import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 55,
        // height: '100%',
        width: '100%',
        borderRadius: 10,
        background: '#fff',
        // boxShadow: '0px 0px 2px #CDCDCD',
        boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
        paddingTop: 30,
        padding: 25
    },
    textBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#909090', 
        fontSize: 18, 
        marginTop: 14, 
        background: '#F5F5F5', 
        padding: 5,
        borderRadius: 5, 
        width: '80%', 
        textAlign: 'center'
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        color: '#909090'
    },
    subtitle: {
        fontSize: 16,
        color: '#C1C1C1',
        marginBottom: 20
    }
})

export const Input = styled.input`
        border: none;
        box-shadow: 0px 0px 2px #CDCDCD;
        font-size: 12pt;
        padding: 15px;
        border-radius: 5px;
        width: 100%;
        color: #8E8E8E;
        
        @media (max-width: 700px) {
            width: 100%;
        }

        &::placeholder {
            color: #949494;
        }
`

export const Button = styled.button`
        background-color: #dc2626;
        color: #fff;
        width: 30%;
        text-align: center;
        border: none;
        border-radius: 5px;
        padding: 13px;
        font-size: 13pt;
        margin-top: 20px;

        @media (max-width: 700px) {
            width: 100%;
        }

        &:hover {
            cursor: pointer;
            transition: 0.2s background;
            background-color: #b91c1c;
        }
`

export const ButtonLoading = styled.button`
        background-color: #DEDEDE;
        color: #898989;
        width: 30%;
        text-align: center;
        border: none;
        border-radius: 5px;
        padding: 13px;
        font-size: 13pt;
        margin-top: 20px;

        @media (max-width: 700px) {
            width: 100%;
        }
`
