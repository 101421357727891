import React, { useEffect, useState } from 'react'

import api from '../../services/api'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ReactGA from 'react-ga'

import { FiPhone } from 'react-icons/fi'
import { GoLocation } from 'react-icons/go'

import { Grid, Paper } from '@material-ui/core'

import Navbar from '../../components/Navbars/Navbar'
import ListVehicles from '../../components/ListVehicles'

import { Container, NoShop, useStyles } from './styles'

import noCarsFoundLogo from '../../assets/images/noCarsFound.svg'
import noShop from '../../assets/images/404.svg'

export default function Garage(props) {
    const { match } = props
    const shopId = String(match.params.shopId)

    const classes = useStyles()

    const [shop, setShop] = useState({})
    const [vehicles, setVehicles] = useState([])
    const [loadingVehicles, setLoadingVehicles] = useState(true)

    useEffect(() => {
        if (String(shopId) === 'undefined') {
            props.history.push('/')
        }

        window.scrollTo(0, 0)

        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        async function loadShop() {
            const shop = await api.get(`shop/${shopId}`)

            setShop(shop.data)
        }

        async function loadVehicles() {
            const response = await api.get(`shop/${shopId}/vehicles`)

            setVehicles(response.data)
            setLoadingVehicles(false)
        }

        Promise.all([loadShop(), loadVehicles()])
    }, [props.history, shopId])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {shop.shop_name
                        ? `${shop.shop_name} | meunovousado.com.br`
                        : 'As melhores promoções de carros usados você encontra aqui!'}
                </title>
            </Helmet>

            <Navbar />

            <Container>
                {
                !loadingVehicles && Object.keys(shop) <= 0 
                ? 
                <NoShop>
                     <img src={noShop} width={350} />
                     <p>Ops! Parece que essa loja não foi encontrada.</p>
                     <small>Você pode encontrar outras lojas em nossa {<Link style={{ textDecoration: 'none', fontWeight: '600', color: 'gray' }} to="/lojas">guia de lojas</Link>}!</small>
                </NoShop>
                : 
                <Grid
                container
                spacing={1}
                style={{
                    // width: '95vw',
                    display: 'flex',
                    justifyContent: 'space-around',
                    margin: '0 auto',
                    // border: '1px red solid'
                }}
            >
                <Grid item xs={12} sm={3}>
                    <Paper
                        className={classes.paper}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <img
                            style={{
                                width: '100px',
                                height: '100px  ',
                                borderRadius: '50%',
                                margin: '0 auto',
                                objectFit: 'cover',
                                background: '#D6D6D6',
                            }}
                            src={
                                shop.profile_picture_path !== null &&
                                shop.profile_picture_path !== ''
                                    ? shop.profile_picture_path
                                    : 'https://images.homedepot-static.com/productImages/fc91cb23-b6db-4d32-b02a-f1ed61dd39a8/svn/folkstone-matte-formica-laminate-sheets-009271258408000-64_400_compressed.jpg'
                            }
                            alt={shop.shop_name}
                        />
                        <p
                            style={{
                                marginBottom: 5,
                                marginTop: 10,
                                color: '#6A6A6A',
                                fontWeight: 500,
                                fontSize: 19
                                // width: '100%'
                            }}
                        >
                            {shop.shop_name
                                ? String(shop.shop_name).slice(0, 20)
                                : null}
                        </p>

                        <p
                            style={{
                                marginBottom: 5,
                                marginTop: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#949494',
                                width: '100%'
                            }}
                        >
                            <FiPhone style={{ marginRight: 10 }} />
                            {shop.phone_number
                                ? ` ${shop.phone_number}`
                                : null}
                        </p>
                      
                        <p
                            style={{
                                padding: 5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#949494',
                            }}
                        >
                            <GoLocation style={{ marginRight: 10 }} />
                            {shop.city}{' '}
                            {shop.state ? `(${shop.state})` : null}
                        </p>

                        {/* <p
                            style={{
                                marginBottom: 5,
                                marginTop: 8,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#949494',
                                width: '100%'
                            }}
                        >
                            <FiSend style={{ marginRight: 10 }} />
                            Visitar loja física
                        </p> */}
                    </Paper>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={9}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {vehicles.length <= 0 && loadingVehicles == false ? (
                        <>
                            <img
                                src={noCarsFoundLogo}
                                width={300}
                                style={{ marginTop: 30 }}
                            />
                            <p
                                style={{
                                    color: '#949494',
                                    fontSize: '15pt',
                                    marginTop: 10,
                                }}
                            >
                                Nenhum veículo encontrado
                            </p>
                        </>
                    ) : (
                        <ListVehicles
                            vehicles={vehicles}
                            loading={loadingVehicles}
                        />
                    )}
                </Grid>
            </Grid>
                }
            </Container>
        </>
    )
}
