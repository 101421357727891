import React, { useState, useEffect } from 'react'

import jwt from 'jsonwebtoken'
import api from '../../../services/api'

import { Tooltip, Grid } from '@material-ui/core'

import ImageUploader from 'react-images-upload'

import { toast } from 'react-toastify'

import {
    Button,
    Typography,
    TextField,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'
import InputMask from 'react-input-mask'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { useStyles } from './styles'

import { colors } from '../../../datas/colors'

export default function ModalAddCar({ isOpen, toggleModal }) {
    const classes = useStyles()

    const [brand, setBrand] = useState('')
    const [brands, setBrands] = useState([])
    const [vehicle, setVehicle] = useState('')
    const [vehicles, setVehicles] = useState([])
    const [year, setYear] = useState('')
    const [price, setPrice] = useState('')
    const [investment, setInvestment] = useState('')
    const [doors, setDoors] = useState('')
    const [color, setColor] = useState('Preto')
    const [licensePlate, setLicensePlate] = useState('')
    const [milage, setMilage] = useState('')
    const [description, setDescription] = useState('')

    const [thumbURL, setThumbURL] = useState(null)
    const [images, setImages] = useState([])
    const [imagesPath, setImagesPath] = useState([])

    const [thumbUploaded, setThumbUploaded] = useState(false)

    const [buttonAddIsLoading, setButtonAddIsLoading] = useState(false)

    const [accessories, setAccessories] = useState([])

    useEffect(() => {
        async function loadBrands() {
            const brands = await api.post('fipe/brands')

            setBrands(brands.data)
        }

        loadBrands()
    }, [])

    async function loadModels(brandId) {
        const brandVehicles = await api.post(`fipe/${brandId}/models`)

        setVehicles(brandVehicles.data.Modelos)
        setVehicle(brandVehicles.data.Modelos[0].Label)
    }

    const handleChangeBrand = (event) => {
        const brandObject = brands.filter((brand) => {
            return String(brand.Label) === String(event)
        })

        let brandId = brandObject[0].Value ? brandObject[0].Value : '6'

        setBrand(event)
        loadModels(brandId)
    }

    const storeVehicle = async () => {
        if (
            brand === '' ||
            vehicle === '' ||
            year === null ||
            year === '' ||
            price <= 0 ||
            price === '' ||
            investment <= 0 ||
            investment === '' ||
            licensePlate === '' ||
            milage <= 0 ||
            images.length <= 0 ||
            doors <= 0 ||
            description === '' ||
            accessories === ''
        ) {
            toast.error(
                '😢 Preencha todos os campos para publicar um novo veículo!'
            )

            return false
        }

        if (description.length > 500) {
            toast.error(
                '😢 A descrição não pode conter mais que 255 caracteres.'
            )

            return false
        }

        if (investment > price) {
            toast.error(
                '😢 Seu investimento não pode ser maior que o preço do veículo.'
            )

            return false
        }

        setButtonAddIsLoading(true)

        const { REACT_APP_JWT_SECRET_KEY } = process.env

        const jwtToken = localStorage.getItem('token')
        const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

        const { id: userId } = jwtPayload

        const vehicleData = {
            brand,
            model: vehicle,
            category: 'carros',
            year,
            price,
            investment,
            doors,
            color,
            fuel_type: 'Gasolina',
            license_plate: licensePlate,
            milage,
            accessories: JSON.stringify(accessories),
            description,
            image_url: thumbURL,
        }

        api.post(`/vehicles/${userId}`, vehicleData)
            .then((response) => {
                const { id: vehicleId } = response.data

                storeImages(vehicleId)

                toast.success('😄 Veículo adicionado com sucesso!')
            })
            .catch((err) => {
                toast.success(
                    '😢 Ops! Algo de estranho aconteceu, tente novamente.'
                )
            })
    }

    const storeImages = (vehicleId) => {
        const imagePromises = []

        for (let image of images) {
            const formData = new FormData()

            formData.append('file', image)
            formData.append('carId', vehicleId)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }

            imagePromises.push(api.post(`files`, formData, config))
        }

        Promise.all(imagePromises)
            .then((promises) => {
                if (promises.length > 0 && !thumbUploaded) {
                    const { url, carId } = promises[0].data
                    updateVehicleThumb(url, carId).then(() => {
                        setThumbUploaded(true)
                        window.location.reload()
                    })
                }
            })
            .catch((err) =>
                toast.error(
                    '😢 Ops! Algo estranho aconteceu ao enviar as imagens, tente novamente.'
                )
            )
    }

    const updateVehicleThumb = (url, id) => {
        return api.put(`vehicles/${id}`, { image_url: url })
    }

    const formImageSubmit = (image, path) => {
        setImagesPath([...imagesPath, path])
        setImages([...images, image])
    }

    const removeImage = (path) => {
        const updatedImagesPath = imagesPath.filter((imagePath) => {
            return imagePath[0] !== path[0]
        })

        setImagesPath(updatedImagesPath)
    }

    const addAccessory = (accessory) => {
        const alreadyHasAccessory = accessories.includes(accessory)

        if (alreadyHasAccessory) {
            const acceessoriesFiltered = accessories.filter(
                (accessoryFiltered) => {
                    return accessoryFiltered !== accessory
                }
            )

            setAccessories(acceessoriesFiltered)
        } else {
            setAccessories([...accessories, accessory])
        }
    }

    return (
        <Dialog
            disableEscapeKeyDown
            open={isOpen}
            onClose={toggleModal}
            maxWidth="xl"
            fullWidth={true}
            fullScreen
        >
            <DialogTitle>Informações do veículo</DialogTitle>

            <DialogContent>
                <div
                    style={{
                        width: '100%',
                        overflowX: 'auto',
                        display: 'flex',
                    }}
                >
                    {imagesPath.map((path, index) => (
                        <Tooltip
                            title="Clique para remover a imagem"
                            onClick={() => removeImage(path)}
                            key={index}
                        >
                            <img
                                src={path}
                                width="250"
                                height="180"
                                style={{
                                    borderRadius: 6,
                                    margin: '10px 10px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                }}
                            />
                        </Tooltip>
                    ))}
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <ImageUploader
                        withIcon={false}
                        withPreview={false}
                        buttonText="Adicionar imagem"
                        onChange={(imageArray, imagePath) =>
                            formImageSubmit(imageArray[0], imagePath)
                        }
                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                        maxFileSize={5242880}
                        singleImage={true}
                        label=" "
                        buttonStyles={{
                            backgroundColor: '#dc2626',
                            borderRadius: 5,
                            padding: 10,
                        }}
                        fileContainerStyle={{
                            boxShadow: 'unset',
                            margin: 0,
                            padding: 0,
                        }}
                    />
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <Autocomplete
                        id="brands"
                        disableClearable
                        noOptionsText="Carregando modelos..."
                        classes={classes}
                        options={brands}
                        getOptionLabel={(brand) => brand.Label}
                        onChange={(event, value) =>
                            handleChangeBrand(value.Label ? value.Label : '')
                        }
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Marcas"
                                variant="outlined"
                            />
                        )}
                    />
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <Autocomplete
                        id="models"
                        disableClearable
                        noOptionsText="Carregando modelos..."
                        classes={classes}
                        options={vehicles}
                        disabled={brand.length > 0 ? false : true}
                        getOptionLabel={(vehicle) => vehicle.Label}
                        onChange={(event, value) => setVehicle(value.Label)}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Modelos"
                                variant="outlined"
                            />
                        )}
                    />
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="9999"
                        value={year ? year : ''}
                        onChange={(event) => setYear(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="Ano"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Preço"
                        type="number"
                        value={price ? price : ''}
                        onChange={(event) =>
                            setPrice(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Investimento"
                        type="number"
                        value={investment ? investment : ''}
                        onChange={(event) =>
                            setInvestment(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Cor"
                        value={color}
                        onChange={(event) => setColor(event.target.value)}
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {colors.map((color) => (
                            <MenuItem key={color} value={color}>
                                {color}
                            </MenuItem>
                        ))}
                    </TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Portas"
                        type="number"
                        value={doors ? doors : ''}
                        onChange={(event) =>
                            setDoors(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Placa"
                        value={licensePlate}
                        onChange={(event) =>
                            setLicensePlate(event.target.value)
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Km"
                        type="number"
                        value={milage}
                        onChange={(event) =>
                            setMilage(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginTop: 10,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Descrição"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        variant="outlined"
                        style={{
                            marginRight: '10px',
                            marginBottom: '20px',
                            width: '100%',
                        }}
                        multiline
                    ></TextField>

                    <Accordion variant="outlined">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Adicionar acessórios</Typography>
                        </AccordionSummary>

                        <AccordionDetails
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                            }}
                            variant="outlined"
                        >
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    '4x4'
                                                )}
                                                onChange={() =>
                                                    addAccessory('4x4')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="4x4"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    '4x2'
                                                )}
                                                onChange={() =>
                                                    addAccessory('4x2')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="4x2"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    '7 lugares'
                                                )}
                                                onChange={() =>
                                                    addAccessory('7 lugares')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="7 lugares"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'ABS'
                                                )}
                                                onChange={() =>
                                                    addAccessory('ABS')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="ABS"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Acendedor de Cigarro'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Acendedor de Cigarro'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Acendedor de Cigarro"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Aerofólio'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Aerofólio')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Aerofólio"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Airbag Lateral Traseiro'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Airbag Lateral Traseiro'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Airbag Lateral Traseiro"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Airbag Lateral'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Airbag Lateral'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Airbag Lateral"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Airbag Cortina'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Airbag Cortina'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Airbag Cortina"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Airbag'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Airbag')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Airbag"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Alarme'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Alarme')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Alarme"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Ar Condicionado'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Ar Condicionado'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Ar Condicionado"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Ar Condicionado Digital'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Ar Condicionado Digital'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Ar Condicionado Digital"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Ar Quente'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Ar Quente')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Ar Quente"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Automático'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Automático')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Automático"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Banco bi-partido'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Banco bi-partido'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Banco bi-partido"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Banco elétrico'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Banco elétrico'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Banco elétrico"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Banco de couro'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Banco de couro'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Banco de couro"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Break Light'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Break Light')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Break Light"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Calotas'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Calotas')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Calotas"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Rodas de Liga Leve'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Rodas de Liga Leve'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Rodas de Liga Leve"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'CD Player'
                                                )}
                                                onChange={() =>
                                                    addAccessory('CD Player')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="CD Player"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Chave Reserva'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Chave Reserva'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Chave Reserva"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Computador de bordo'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Computador de bordo'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Computador de bordo"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Console central'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Console central'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Console central"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Controle de som no volante'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Controle de som no volante'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Controle de som no volante"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Controle de tração'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Controle de tração'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Controle de tração"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Retrovisor elétrico'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Retrovisor elétrico'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Retrovisor elétrico"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Pisca no retrovisor'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Pisca no retrovisor'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Pisca no retrovisor"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Conversível'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Conversível')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Conversível"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Desembaçador traseiro'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Desembaçador traseiro'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Desembaçador traseiro"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Direção elétrica'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Direção elétrica'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Direção elétrica"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Direção hidraulica'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Direção hidraulica'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Direção hidraulica"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Encosto de Cabeça traseiro'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Encosto de Cabeça traseiro'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Encosto de Cabeça traseiro"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Farol de Milha'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Farol de Milha'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Farol de Milha"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Farolete'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Farolete')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Farolete"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Lampadas de LED'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Lampadas de LED'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Lampadas de LED"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Xenon'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Xenon')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Xenon"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Limpador traseiro'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Limpador traseiro'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Limpador traseiro"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Painel Digital'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Painel Digital'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Painel Digital"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Partida Elétrica'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Partida Elétrica'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Partida Elétrica"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Película'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Película')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Película"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Piloto automático'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Piloto automático'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Piloto automático"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Porta malas automático'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Porta malas automático'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Porta malas automático"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Porta copos'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Porta copos')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Porta copos"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Protetor de caçamba'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Protetor de caçamba'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Protetor de caçamba"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Protetor e cárter'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Protetor e cárter'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Protetor e cárter"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Sensor de chuva'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Sensor de chuva'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Sensor de chuva"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Teto solar'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Teto solar')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Teto solar"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Direção Hidraulica'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Direção Hidraulica'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Direção Hidraulica"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Volante escamoteavel'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Volante escamoteavel'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Volante escamoteavel"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Interface'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Interface')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Interface"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'DVD'
                                                )}
                                                onChange={() =>
                                                    addAccessory('DVD')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="DVD"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Aquecimento nos bancos'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Aquecimento nos bancos'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Aquecimento nos bancos"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Trava Elétrica'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Trava Elétrica'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Trava"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Vidro Elétrico'
                                                )}
                                                onChange={() =>
                                                    addAccessory(
                                                        'Vidro Elétrico'
                                                    )
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Vidro"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Som'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Som')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Som"
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Sensor de ré'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Sensor de ré')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Sensor de ré"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Câmera de ré'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Câmera de ré')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Câmera de ré"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={accessories.includes(
                                                    'Blindado'
                                                )}
                                                onChange={() =>
                                                    addAccessory('Blindado')
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label="Blindado"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={toggleModal}
                    color="primary"
                    style={{ color: '#969696' }}
                >
                    Cancelar
                </Button>

                {buttonAddIsLoading ? (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#E3E3E3' }}
                        disableElevation
                        disabled
                    >
                        Adicionando...
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => storeVehicle()}
                        color="primary"
                        style={{ backgroundColor: '#dc2626' }}
                        disableElevation
                    >
                        Adicionar
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
