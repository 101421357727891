import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        // padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: 'none',
        boxShadow: 'none',
        border: 'none',
        paddingTop: '16px'
    },
}))

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 80px;
    /* border: 1px red solid; */

    @media (max-width: 1920px) {
        width: 60%;
    }

    @media (max-width: 1440px) {
        width: 70%;
    }

    @media (max-width: 1024px) {
        width: 95%;
        margin-top: 0px;
    }
`

export const GarageProfile = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: 400px;
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 5px;
    margin-right: 50px;

    @media (max-width: 1024px) {
        width: 30%;
    }

    h1 {
        font-size: 13pt;
        color: #949494;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    span {
        font-size: 12pt;
        color: #949494;
        margin-top: 8px;
    }

    img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-top: 20px;
    }
`

export const GarageVehicles = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;
`

export const Title = styled.h2`
    font-size: 15pt;
    font-weight: bold;
    color: #949494;

    small {
        font-size: 12pt;
        font-weight: lighter;
    }
`

export const ListVehicles = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

`

export const Vehicle = styled.div`
    background-color: #fff;
    width: 230px;
    height: 240px;
    text-align: center;
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 4px;
    margin-right: 30px;
    margin-bottom: 30px;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 100%;
        height: 60%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #F8F8F8;
    }
`

export const VehicleTitle = styled.p`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #949494;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;

    p {
        font-size: 13pt;
    }

    span {
        margin-top: 4px;
        font-size: 12pt;
        font-weight: bold;
    }
`

export const VehicleRedirect = styled(Link)`
    text-decoration: none;
`

export const NoShop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 40px;

    p {
        font-size: 14pt;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
        color: #878787;
    }

    small {
        text-align: center;
        color: #878787;
    }
`