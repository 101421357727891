import styled from 'styled-components'

export const HeaderContainer = styled.div`
    display: block;
    width: 100%;
    height: 30% !important;
    background-color: #fff;
    padding-bottom: 50px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 2px #cdcdcd;
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 80px;
    color: #878787;

    @media (max-width: 1440px) {
        width: 50%;
        margin-top: 80px;
    }

    @media (max-width: 1024px) {
        width: 70%;
        margin-top: 80px;
    }

    @media (max-width: 800px) {
        width: 90%;
        margin-top: 80px;
    }

    @media (max-width: 500px) {
        width: 90%;
        margin-top: 80px;
    }

    h1 {
        font-size: 14pt;
        color: #949494;
        margin-bottom: 10px;

        @media (max-width: 600px) {
            font-size: 11pt;
        }
    }

    input[type='text'] {
        border: none;
        box-shadow: 0px 0px 3px #ccc;
        padding: 14px;
        border-radius: 15px;
        width: 100%;

        &::placeholder {
            color: #949494;
        }

        @media (max-width: 800px) {
            padding: 16px;
            margin-bottom: 10px;
        }
    }

    button {
        background-color: #dc2626;
        display: flex;
        color: #fff;
        height: 100%;
        border: none;
        box-shadow: 0px 0px 2px #ccc;
        border-radius: 0px 5px 5px 0px;
        padding: 12px;
        font-size: 13pt;
        margin-left: -300px;

        &:hover {
            transition: 0.1s background;
            background-color: #b91c1c;
            cursor: pointer;
        }

        @media (max-width: 800px) {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            border-radius: 5px 5px 5px 5px;
        }
    }
`

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-color: #f7f7f7;
    width: 100%;
    height: 200px;
    color: #878787;

    span {
        display: flex;
        background-color: #e4e4e4;
        font-size: 13pt;
        color: #949494;
        padding: 14px;
        justify-content: center;
    }

    li {
        text-decoration: none;
        list-style-type: none;
        color: #878787;
    }
`

export const SearchButton = styled.button`
    background-color: #dc2626;
    display: flex;
    color: #fff;
    height: 100%;
    border: none;
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 5px;
    padding: 12px;
    font-size: 13pt;
    margin-left: 10px;

    &:hover {
        transition: 0.1s background;
        background-color: #b91c1c;
        cursor: pointer;
    }

    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        border-radius: 5px 5px 5px 5px;
    }
`
export const SearchBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`
export const SearchInput = styled.input`
    border: none;
    box-shadow: 0px 0px 2px #ccc;
    padding: 14px;
    border-radius: 5px;
    width: 80%;

    &::placeholder {
        color: #9b9b9b;
    }

    &:focus {
        box-shadow: 0px 0px 2px #ccc;
    }

    @media (max-width: 800px) {
        width: 100%;
        margin-bottom: 10px;
    }
`
