import React from 'react'

import { Grid, Paper, CircularProgress } from '@material-ui/core'

import { useStyles } from './styles'

import speedometerIcon from '../../assets/images/speedometer.svg'
import calendarIcon from '../../assets/images/calendar.svg'
import fuelIcon from '../../assets/images/fuel.svg'
import brushIcon from '../../assets/images/brush.svg'
import carPlateIcon from '../../assets/images/carPlate.svg'
import carDoorIcon from '../../assets/images/door.svg'

export default function VehicleDetails({ vehicle }) {
    const classes = useStyles()

    return (
        <>
            <Grid item xs={6} sm={4}>
                <Paper className={classes.paperIcon}>
                    <img
                        src={speedometerIcon}
                        alt="Velocidade"
                        className={classes.paperIconImg}
                    />{' '}
                    {vehicle.milage ? (
                        Number(vehicle.milage).toLocaleString('pt-BR') + ' Km'
                    ) : (
                        <CircularProgress size={20} color="inherit" variant="indeterminate" />
                    )}
                </Paper>
            </Grid>

            <Grid item xs={6} sm={4}>
                <Paper className={classes.paperIcon}>
                    <img src={calendarIcon} alt="Calendário" className={classes.paperIconImg} />{' '}
                    {vehicle.year ? (
                        vehicle.year
                    ) : (
                        <CircularProgress size={20} color="inherit" variant="indeterminate" />
                    )}
                </Paper>
            </Grid>

            <Grid item xs={6} sm={4}>
                <Paper className={classes.paperIcon}>
                    <img src={fuelIcon} alt="Combustível" className={classes.paperIconImg} />{' '}
                    {vehicle.fuel_type ? (
                        vehicle.fuel_type
                    ) : (
                        <CircularProgress size={20} color="inherit" variant="indeterminate" />
                    )}
                </Paper>
            </Grid>

            <Grid item xs={6} sm={4}>
                <Paper className={classes.paperIcon}>
                    <img src={brushIcon} alt="Cor" className={classes.paperIconImg} />{' '}
                    {vehicle.color ? (
                        vehicle.color
                    ) : (
                        <CircularProgress size={20} color="inherit" variant="indeterminate" />
                    )}
                </Paper>
            </Grid>

            <Grid item xs={6} sm={4}>
                <Paper className={classes.paperIcon}>
                    <img src={carDoorIcon} alt="Portas" className={classes.paperIconImg} />{' '}
                    {vehicle.doors ? (
                        `${vehicle.doors} portas`
                    ) : (
                        <CircularProgress size={20} color="inherit" variant="indeterminate" />
                    )}
                </Paper>
            </Grid>

            <Grid item xs={6} sm={4}>
                <Paper className={classes.paperIcon}>
                    <img src={carPlateIcon} alt="Placa" className={classes.paperIconImg} />
                    <p style={{ marginBottom: 5 }}>Final placa</p>
                    {vehicle.license_plate ? (
                        String(vehicle.license_plate).substr(
                            String(vehicle.license_plate).length - 1
                        )
                    ) : (
                        <CircularProgress size={20} color="inherit" variant="indeterminate" />
                    )}
                </Paper>
            </Grid>
        </>
    )
}
