import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import api from '../../services/api'

import { generateVehicleURLString } from '../../utils/helpers/formatters/formatVehicleURL'

import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Divider,
    Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import noVehicleImage from '../../assets/images/noCars.jpeg'

function VehicleSugestions(props) {
    const [vehicles, setVehicles] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function loadVehicles() {
            const response = await api.post('vehicleSugestions', {
                searchParams: props.currentVehicle.model.split(' ')[0],
                searchParamsBrands: props.currentBrand,
                currentVehicleId: props.currentVehicle.id,
            })

            const filteredVehicles = filterVehicles(response.data)

            setVehicles(filteredVehicles)
            setLoading(false)
        }

        loadVehicles()
    }, [])

    const filterVehicles = (vehicles) => {
        const filteredVehicles = vehicles.filter((vehicle) => {
            return vehicle.id !== props.currentVehicle.id
        })

        return filteredVehicles
    }

    return (
        <>
            {
                vehicles.length > 0
                    ? (
                        <>
                            <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
                                <Divider variant="fullWidth" style={{ width: '15%' }} />
                            </Grid>

                            <Typography variant="h6" component="h6" gutterBottom>
                                Confira outras opções
                            </Typography>
                        </>
                    )
                    : null
            }
            <Grid container spacing={1} style={{ marginTop: 15 }}>
                {loading ? (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <Skeleton
                                    variant="rect"
                                    height={150}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 10,
                                        width: '100%',
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Skeleton
                                    variant="rect"
                                    width={220}
                                    height={150}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 10,
                                        width: '100%',
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Skeleton
                                    variant="rect"
                                    width={220}
                                    height={150}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 10,
                                        width: '100%',
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Skeleton
                                    variant="rect"
                                    width={220}
                                    height={150}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 10,
                                        width: '100%',
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={10}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 8,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    vehicles.map((vehicle) => {
                        return (
                            <Grid item xs={6} sm={6} md={3}>
                                <Link
                                    to={generateVehicleURLString(vehicle)}
                                    style={{ textDecoration: 'none' }}
                                    target="_blank"
                                >
                                    <Card
                                        style={{ height: 280 }}
                                        variant="outlined"
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="Contemplative Reptile"
                                            height="140"
                                            image={
                                                vehicle.image_url ||
                                                    vehicle.image_url !== ''
                                                    ? vehicle.image_url
                                                    : noVehicleImage
                                            }
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                variant="p"
                                                component="p"
                                                style={{
                                                    color: '#363636',
                                                    fontSize: 19,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {'R$ ' +
                                                    vehicle.price.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}

                                                <Typography
                                                    gutterBottom
                                                    variant="small"
                                                    component="small"
                                                    style={{
                                                        color: '#474747',
                                                        fontSize: 13,
                                                        marginTop: 3,
                                                    }}
                                                >
                                                    {vehicle.year} |{' '}
                                                    {vehicle.milage.toLocaleString(
                                                        'pt-BR'
                                                    )}{' '}
                                                    Km
                                                </Typography>
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                component="h6"
                                                style={{
                                                    fontSize: 14,
                                                    color: '#6F6F6F',
                                                }}
                                            >
                                                {vehicle.model}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        )
                    })
                )}
            </Grid>
        </>
    )
}

export default VehicleSugestions
