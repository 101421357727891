export const generateVehicleURLString = (vehicle) => {
    const { model, id } = vehicle

    const nameString = normalizeVehicleName(model, 0)
    const modelString = normalizeVehicleName(model, 1)

    const url = `/veiculo/${nameString}-${modelString}/${id}`

    return url
}

const normalizeVehicleName = (value, splitIndex) => {
    const normalizedString = value
        .split(' ')[splitIndex]
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace('.', '')
        .toLowerCase()
        .replace(/[^\w\s]/gi, '')

    return normalizedString
}
