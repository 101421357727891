import React, { useState, useEffect } from 'react'

import api from '../../../services/api'
import jwt from 'jsonwebtoken'
import { Helmet } from 'react-helmet'

import { ToastContainer, toast } from 'react-toastify'

import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Paper,
    Grid,
    Typography,
    CircularProgress,
} from '@material-ui/core/'

import NavbarDashboard from '../../../components/Navbars/NavbarDashboard'
import LineChart from '../../../components/Charts/AnalyticsLineChart'
import PieChart from '../../../components/Charts/AnalyticsPieChart'

import chartLogo from '../../../assets/images/chart.svg'

import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import { BiPin, BiCar } from 'react-icons/bi'

import Skeleton from '@material-ui/lab/Skeleton'

import { useStyles } from './styles'

export default function DashboardAnalytics() {
    const classes = useStyles()

    const { REACT_APP_JWT_SECRET_KEY } = process.env

    const jwtToken = localStorage.getItem('token')
    const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

    const { id: shopId } = jwtPayload

    const [reports, setReports] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [loadingVehicles, setLoadingVehicles] = useState(true)
    const [loadingReports, setLoadingReports] = useState(true)
    const [totalEarnings, setTotalEarnings] = useState(0)
    const [earnings, setEarnings] = useState(0)
    const [stockTotalPrice, setStockTotalPrice] = useState(0)

    const currentMonth = new Date().toLocaleString('default', { month: 'long' })

    useEffect(() => {
        async function loadReports() {
            const response = await api.get(`shop/${shopId}/reports`)

            setReports(response.data)
            setLoadingReports(false)

            sumEarnings(response.data)
            sumTotalEarnings(response.data)
        }

        async function loadVehicles() {
            const response = await api.get(`shop/${shopId}/vehicles`)

            setVehicles(response.data)
            setLoadingVehicles(false)

            sumStockTotalPrice(response.data)
        }

        if (shopId) {
            Promise.all([loadReports(), loadVehicles()])
        }
    }, [shopId])

    async function deleteReport(id) {
        await api.delete(`reports/${id}`)

        toast.success('Registro deletado com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 800)
    }

    function sumStockTotalPrice(data) {
        const sumOfTotalPrice = data.reduce((prev, current) => {
            return prev + +current.price
        }, 0)

        setStockTotalPrice(sumOfTotalPrice)
    }

    function sumEarnings(data) {
        const sumOfEarnings = data.reduce((prev, current) => {
            return prev + +current.investment
        }, 0)

        setEarnings(sumOfEarnings)
    }

    function sumTotalEarnings(data) {
        const sumOfTotalEarnings = data.reduce((prev, current) => {
            return prev + +current.price
        }, 0)

        setTotalEarnings(sumOfTotalEarnings)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Estatísticas | meunovousado.com.br</title>
            </Helmet>

            <NavbarDashboard />

            <ToastContainer autoClose={3000} />

            <Container maxWidth="md" className={classes.container}>
                <img
                    src={chartLogo}
                    width="200"
                    height="200"
                    alt="Gráfico"
                    style={{ borderRadius: '50%' }}
                />

                <Typography
                    gutterBottom
                    variant="p"
                    component="p"
                    style={{
                        color: '#737373',
                        fontSize: 20,
                        textAlign: 'left',
                        width: '100%',
                        padding: '0px 12px 12px 0px',
                    }}
                >
                    Seus resultados em{' '}
                    <b>{capitalizeFirstLetter(currentMonth)}</b>
                    <Typography
                        gutterBottom
                        variant="p"
                        component="p"
                        style={{
                            color: '#AEAEAE',
                            fontSize: 15,
                            margin: 0,
                            marginBottom: 20,
                        }}
                    >
                        Passe o mouse por cima dos cards para saber mais
                        detalhes.
                    </Typography>
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <Paper className={classes.paper} variant="outlined">
                            <FaRegMoneyBillAlt size={45} color="#dc2626" />

                            <Typography
                                gutterBottom
                                variant="p"
                                component="p"
                                style={{ color: '#969696' }}
                            >
                                Ganhos totais
                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    style={{
                                        color: '#dc2626',
                                        fontWeight: 'bold',
                                        fontSize: 18,
                                    }}
                                >
                                    {loadingReports ? (
                                        <CircularProgress
                                            size={20}
                                            color="inherit"
                                            variant="indeterminate"
                                        />
                                    ) : (
                                        'R$ ' +
                                        totalEarnings.toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                        })
                                    )}
                                </Typography>
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Tooltip
                            title={
                                <p style={{ fontSize: 14 }}>
                                    Lucro estimado de
                                    {loadingReports ? (
                                        <CircularProgress
                                            size={20}
                                            color="inherit"
                                            variant="indeterminate"
                                        />
                                    ) : (
                                        ' R$ ' +
                                        earnings.toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                        })
                                    )}
                                </p>
                            }
                            placement="top"
                        >
                            <Paper className={classes.paper} variant="outlined">
                                <BiPin size={45} color="#dc2626" />

                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    style={{ color: '#969696' }}
                                >
                                    Lucro estimado
                                    <Typography
                                        gutterBottom
                                        variant="p"
                                        component="p"
                                        style={{
                                            color: '#dc2626',
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                        }}
                                    >
                                        {loadingReports ? (
                                            <CircularProgress
                                                size={20}
                                                color="inherit"
                                                variant="indeterminate"
                                            />
                                        ) : (
                                            ' R$ ' +
                                            earnings.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                            })
                                        )}
                                    </Typography>
                                </Typography>
                            </Paper>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Tooltip
                            title={
                                <p style={{ fontSize: 14 }}>
                                    Lucro estimado de
                                    {loadingReports ? (
                                        <CircularProgress
                                            size={20}
                                            color="inherit"
                                            variant="indeterminate"
                                        />
                                    ) : (
                                        ' R$ ' +
                                        earnings.toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                        })
                                    )}
                                </p>
                            }
                            placement="top"
                        >
                            <Paper className={classes.paper} variant="outlined">
                                <BiPin size={45} color="#dc2626" />

                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    style={{ color: '#969696' }}
                                >
                                    Vendidos
                                    <Typography
                                        gutterBottom
                                        variant="p"
                                        component="p"
                                        style={{
                                            color: '#dc2626',
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                        }}
                                    >
                                        {loadingReports ? (
                                            <CircularProgress
                                                size={20}
                                                color="inherit"
                                                variant="indeterminate"
                                            />
                                        ) : (
                                            reports.length
                                        )}
                                    </Typography>
                                </Typography>
                            </Paper>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Tooltip
                            title={
                                <p style={{ fontSize: 14 }}>
                                    Equivalentes a
                                    {loadingReports ? (
                                        <CircularProgress
                                            size={20}
                                            color="inherit"
                                            variant="indeterminate"
                                        />
                                    ) : (
                                        ' R$ ' +
                                        stockTotalPrice.toLocaleString(
                                            'pt-BR',
                                            {
                                                minimumFractionDigits: 2,
                                            }
                                        )
                                    )}
                                </p>
                            }
                            placement="top"
                        >
                            <Paper className={classes.paper} variant="outlined">
                                <BiCar size={45} color="#dc2626" />

                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    style={{ color: '#969696' }}
                                >
                                    No estoque
                                    <Typography
                                        gutterBottom
                                        variant="p"
                                        component="p"
                                        style={{
                                            color: '#dc2626',
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                        }}
                                    >
                                        {loadingVehicles ? (
                                            <CircularProgress
                                                size={20}
                                                color="inherit"
                                                variant="indeterminate"
                                            />
                                        ) : (
                                            vehicles.length
                                        )}
                                    </Typography>
                                </Typography>
                            </Paper>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Paper
                            variant="outlined"
                            style={{
                                boxShadow:
                                    'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                            }}
                        >
                            <LineChart data={reports} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Paper
                            variant="outlined"
                            style={{
                                boxShadow:
                                    'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                            }}
                        >
                            <PieChart data={reports} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#737373',
                                fontSize: 20,
                                textAlign: 'left',
                                width: '100%',
                                padding: '0px 12px 12px 0px',
                            }}
                        >
                            Relatório de vendas
                        </Typography>

                        <TableContainer
                            component={Paper}
                            style={{
                                borderBottom: 'none',
                                marginBottom: 50,
                                boxShadow:
                                    'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                            }}
                            variant="outlined"
                        >
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell align="right">
                                            Marca
                                        </TableCell>
                                        <TableCell align="right">
                                            Modelo
                                        </TableCell>
                                        <TableCell align="right">
                                            Preço
                                        </TableCell>
                                        <TableCell align="right">
                                            Opções
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {loadingReports ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Skeleton
                                                    variant="rect"
                                                    style={{
                                                        width: '100vw',
                                                        height: 15,
                                                        borderRadius: 5,
                                                        marginLeft: 10,
                                                        marginTop: 20,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Skeleton
                                                    variant="rect"
                                                    style={{
                                                        width: '100vw',
                                                        height: 15,
                                                        borderRadius: 5,
                                                        marginLeft: 10,
                                                        marginTop: 20,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Skeleton
                                                    variant="rect"
                                                    style={{
                                                        width: '100vw',
                                                        height: 15,
                                                        borderRadius: 5,
                                                        marginLeft: 10,
                                                        marginTop: 20,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Skeleton
                                                    variant="rect"
                                                    style={{
                                                        width: '100vw',
                                                        height: 15,
                                                        borderRadius: 5,
                                                        marginLeft: 10,
                                                        marginTop: 20,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Skeleton
                                                    variant="rect"
                                                    style={{
                                                        width: '100vw',
                                                        height: 15,
                                                        borderRadius: 5,
                                                        marginLeft: 10,
                                                        marginTop: 20,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {reports.map((report) => (
                                            <>
                                                <TableRow key={report.id}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        #{report.id}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {String(
                                                            report.brand
                                                        ).toUpperCase()}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {report.model}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {'R$ ' +
                                                            report.price.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip
                                                            title="Excluir registro"
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                aria-label="edit"
                                                                onClick={() =>
                                                                    window.confirm(
                                                                        'Tem certeza que deseja deletar um registro? Essa ação não poderá ser revertida.'
                                                                    )
                                                                        ? deleteReport(
                                                                              report.id
                                                                          )
                                                                        : null
                                                                }
                                                            >
                                                                <AiOutlineDelete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
