import styled from 'styled-components'

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 60%;
    margin: 0 auto;

    p {
        color: #949494;
        font-size: 15pt;
        margin-top: 10px;
    }

    img {
        width: 300px;
        margin-top: 50px;
    }
`

export const SearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 60%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
    
    @media (max-width: 1920px) {
        max-width: 60%;
    }

    @media (max-width: 1440px) {
        max-width: 70%;
        margin-top: 40px;
    }

    @media (max-width: 1024px) {
        max-width: 90%;
        margin-top: 30px;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        max-width: 100%;
        margin-top: 20px;
    }

    p {
        @media (max-width: 1024px) {
            width: 88%;
            margin-bottom: 14px;
        }
    }

    h2 {
        font-size: 16pt;
        font-weight: bold;
        color: #949494;

        @media (max-width: 1024px) {
            font-size: 14pt;
        }

        @media (max-width: 700px) {
            display: none;
        }
    }

    small {
        font-size: 12pt;
        font-weight: lighter;

        @media (max-width: 500px) {
            font-size: 8pt;
        }
    }
`

export const Input = styled.input`
        border: none;
        box-shadow: 0px 0px 1px #ccc;
        padding: 15px;
        border-radius: 5px;
        width: 68%;

        @media (max-width: 1920px) {
             max-width: 80%;
        }

        @media (max-width: 1440px) {
            max-width: 60%;
        }

        @media (max-width: 1024px) {
            max-width: 50%;
        }

        @media (max-width: 700px) {
            max-width: 90%;
            width: 100%;
            padding: 15px;
        }

        &::placeholder {
            color: #949494;
        }

        &:focus {
            box-shadow: 0px 0px 2px #ccc; 
        }
`

export const Button = styled.button`
        background-color: #dc2626;
        display: flex;
        color: #fff;
        height: 100%;
        border: none;
        border-radius: 5px;
        padding: 12px;
        font-size: 13pt;
        box-shadow: 0px 0px 1px #ccc;

        @media (max-width: 700px) {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            width: 90%;
            padding: 15px;
            border-radius: 5px 5px 5px 5px;
        }

        &:hover {
            background-color: #b91c1c;
            cursor: pointer;
        }
`