import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import { getToken } from '../../services/auth'

import { Link } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify'

import { CircularProgress } from '@material-ui/core'

import ReactGA from 'react-ga'

import logo from '../../assets/images/logo.png'

import {
    Container,
    Logo,
    Input,
    Button,
    ButtonLoading,
    SignUpRedirect,
} from './styles'

export default function SignIn(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        const isLogged = localStorage.getItem('token')

        if (isLogged) {
            props.history.push('/dashboard')
        }
    }, [])

    async function signIn() {
        setLoading(true)

        const data = {
            email,
            password,
        }

        await api
            .post('/sessions', data)
            .then((response) => {
                const { token } = response.data

                toast.success('Login efetuado com sucesso!')

                localStorage.setItem('token', token)

                setTimeout(() => {
                    props.history.push('/dashboard')
                }, 500)
            })
            .catch((err) => {
                toast.error(
                    'Falha ao efetuar login. Usuário ou senha incorretos!'
                )
                setLoading(false)
            })
    }

    return (
        <>
            <ToastContainer autoClose={3000} />

            <Container>
                <Link to="/">
                    <Logo src={logo} alt="FindCar" />
                </Link>

                <Input
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    placeholder="Senha"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => (e.key === 'Enter' ? signIn() : null)}
                />

                {loading ? (
                    <ButtonLoading>
                        <CircularProgress
                            color="#878787"
                            size={18}
                            style={{ marginRight: 10 }}
                        />
                        Entrando...
                    </ButtonLoading>
                ) : (
                    <Button onClick={() => signIn()} disabled={loading}>
                        Entrar
                    </Button>
                )}

                <p>
                    Novo por aqui?{' '}
                    <SignUpRedirect to="/administrativo">
                        Cadastre-se agora
                    </SignUpRedirect>
                </p>
            </Container>
        </>
    )
}
