import React from 'react'

import { Link } from 'react-router-dom'

import { Container, Title, GarageName } from './styles'

import { Grid, Card } from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import cars from '../../assets/images/cars.svg'

export default function ListGarages({
    shops,
    showTitle,
    title,
    loading,
    limit,
}) {
    const formattedShops = limit ? shops.slice(0, limit) : shops
    return (
        <>
            <Container>
                {showTitle ? <Title>{title}</Title> : null}

                <Grid container spacing={1}>
                    {loading ? (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={2}>
                                    <Skeleton
                                        variant="rect"
                                        width={130}
                                        height={135}
                                        style={{
                                            borderRadius: 5,
                                            marginLeft: 10,
                                            marginTop: 10,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <Skeleton
                                        variant="rect"
                                        width={130}
                                        height={135}
                                        style={{
                                            borderRadius: 5,
                                            marginLeft: 10,
                                            marginTop: 10,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <Skeleton
                                        variant="rect"
                                        width={130}
                                        height={135}
                                        style={{
                                            borderRadius: 5,
                                            marginLeft: 10,
                                            marginTop: 10,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : formattedShops.length > 0 ? (
                        formattedShops &&
                        formattedShops.map((shop) => {
                            const { shop_name, id, profile_picture_path } = shop

                            return (
                                <Grid item xs={6} sm={2} key={id}>
                                    <Link
                                        to={`/loja/${shop_name
                                            .replace(' ', '-')
                                            .toLowerCase()}/${id}`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Card
                                            style={{
                                                width: 135,
                                                height: 140,
                                                padding: 7,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            variant="outlined"
                                        >
                                            <img
                                                src={
                                                    profile_picture_path !==
                                                    null
                                                        ? profile_picture_path
                                                        : 'https://images.homedepot-static.com/productImages/fc91cb23-b6db-4d32-b02a-f1ed61dd39a8/svn/folkstone-matte-formica-laminate-sheets-009271258408000-64_400_compressed.jpg'
                                                }
                                                alt={shop_name}
                                                style={{
                                                    width: '50%',
                                                    height: '50%',
                                                    marginBottom: 14,
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            <GarageName>{shop_name}</GarageName>
                                        </Card>
                                    </Link>
                                </Grid>
                            )
                        })
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 15,
                                flexDirection: 'column',
                            }}
                        >
                            <img src={cars} width={250} />
                            <p style={{ marginTop: 10, fontSize: 18 }}>
                                Ops! Essa loja não foi encontrada, mas em breve
                                ela pode estar aqui.
                            </p>
                        </div>
                    )}
                </Grid>
            </Container>
        </>
    )
}
