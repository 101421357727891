import React from 'react'

import {
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core'

import giftLogo from '../../../assets/images/gift.svg'

export default function ModalEditCar({ isOpen, toggleModal }) {
    return (
        <Dialog disableEscapeKeyDown open={isOpen} onClose={toggleModal} maxWidth="sm">
            <DialogTitle></DialogTitle>

            <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={giftLogo} alt="Gift Logo" width={200} />

                <Typography gutterBottom variant="p" component="p" style={{ color: '#969696', fontSize: 18, marginTop: 15, textAlign: 'center' }}>
                    Durante este período de pandemia, nossos planos estão totalmente gratuitos.
                </Typography>
            </DialogContent>

            <DialogActions style={{ padding: 10 }}>
                <Button variant="contained" onClick={() => toggleModal()} style={{ backgroundColor: '#FF5A5F' }} color="primary" disableElevation>
                    Fechar
                </Button>

            </DialogActions>
        </Dialog>
    )
}
