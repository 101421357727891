import React, { PureComponent } from 'react'
import { ResponsiveContainer, PieChart, Pie } from 'recharts'

export default class AnalyticsPieChart extends PureComponent {
    render() {
        return (
            <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            dataKey="price"
                            data={this.props.data}
                            fill="#FF5A5F"
                            label
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        )
    }
}
