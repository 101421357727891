import React, { useState } from 'react'

import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

import { useStyles } from './styles'

import CheckIcon from '@material-ui/icons/Check'

export default function VehicleAccessories({ vehicle }) {
    const classes = useStyles()

    const [dense, setDense] = useState(false)
    const [secondary, setSecondary] = useState(false)

    const { accessories } = vehicle

    let accessoriesFormated = JSON.parse(accessories)
    accessoriesFormated = accessoriesFormated.sort((a, b) => {
        if (a < b) {
            return -1
        }
        if (a > b) {
            return 1
        }
        return 0
    })

    return (
        <>
            <Grid item xs={12} md={12}>
                <div className={classes.demo}>
                    <List
                        dense={dense}
                        style={{ height: 200, overflow: 'auto' }}
                    >
                        {accessoriesFormated.length > 0
                            ? accessoriesFormated.map((accessory) => (
                                  <ListItem key={accessory}>
                                      <ListItemIcon>
                                          <CheckIcon />
                                      </ListItemIcon>
                                      <ListItemText
                                          primary={accessory}
                                          secondary={secondary ? ' ' : null}
                                      />
                                  </ListItem>
                              ))
                            : 'Nenhum acessório cadastrado.'}
                    </List>
                </div>
            </Grid>
        </>
    )
}
