import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

import { Link } from 'react-router-dom'

import Navbar from '../../components/Navbars/Navbar'
import Footer from '../../components/Footer'
import ModalPlans from '../../components/Modals/ModalPlans'

import { Grid, Hidden, Paper } from '@material-ui/core'

import painelLogo from '../../assets/images/painelLogo.svg'

import { HeaderContainer, Section, Button, useStyles } from './styles'

import { BiLineChart } from 'react-icons/bi'
import { AiOutlineEye, AiOutlineCar } from 'react-icons/ai'

export default function SignIn() {
    const [isOpen, setIsOpen] = useState(false)

    const classes = useStyles()

    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Navbar />

            <Helmet>
                <meta charSet="utf-8" />
                <title>Administrativo | meunovousado.com.br</title>
                <meta
                    name="description"
                    content="Gerêncie sua loja por meio de nosso dashboard com estatísticas detalhadas de vendas"
                ></meta>
                <meta
                    name="keywords"
                    content="carros usados, carros em criciúma e região, carros usados a venda, carros a venda"
                ></meta>
            </Helmet>

            <HeaderContainer>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        paddingTop: 40,
                        margin: '0 auto',
                        width: '85vw',
                    }}
                >
                    <Grid
                        item
                        xs="auto"
                        xl={3}
                        sm={5}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p style={{ fontSize: 26, color: '#797979' }}>
                            Gerencie sua loja conosco
                        </p>
                        <p
                            style={{
                                fontSize: 17,
                                color: '#A8A8A8',
                                marginTop: 10,
                            }}
                        >
                            Em nosso dashboard você poderá gerenciar todo o
                            estoque da sua loja, com relatórios personalizados e
                            analise de vendas.
                        </p>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 20,
                                marginBottom: 5,
                            }}
                        >
                            <Hidden smUp>
                                <img
                                    src={painelLogo}
                                    width="280"
                                    alt="Logo"
                                    style={{ marginRight: '6px' }}
                                />
                            </Hidden>
                        </div>

                        <div
                            style={{
                                marginTop: 60,
                                marginBottom: 50,
                                width: '100%',
                            }}
                        >
                            <Link to="/signin">
                                <Button>Acessar painel</Button>
                            </Link>

                            <a
                                onClick={() => toggleModal()}
                                style={{
                                    textDecoration: 'none',
                                    color: '#797979',
                                }}
                            >
                                Ver planos
                            </a>
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs="auto"
                        xl={2}
                        sm={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Hidden only="xs">
                            <img
                                src={painelLogo}
                                width="350"
                                alt="Logo"
                                style={{ marginRight: '6px' }}
                            />
                        </Hidden>
                    </Grid>
                </Grid>
            </HeaderContainer>

            <Section>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} className={classes.gridItem}>
                        <Paper className={classes.paper}>
                            <AiOutlineCar size={28} color="#FF5A5F" />
                        </Paper>
                        <p>Anúncios ilimitados</p>
                        <small>Anúncie sem limites e sem taxa adicional.</small>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gridItem}>
                        <Paper className={classes.paper}>
                            <BiLineChart size={28} color="#FF5A5F" />
                        </Paper>
                        <p>Relatório de vendas</p>
                        <small>Relatório detalhado com estatísticas de vendas.</small>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gridItem}>
                        <Paper className={classes.paper}>
                            <AiOutlineEye size={28} color="#FF5A5F" />
                        </Paper>
                        <p>Métrica de visitas</p>
                        <small>Saiba quantas visualizações cada anúncio teve.</small>
                    </Grid>
                </Grid>
            </Section>

            <ModalPlans isOpen={isOpen} toggleModal={toggleModal} />

            <Footer />
        </>
    )
}
