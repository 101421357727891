import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../../assets/images/logo.png'

import { Container, Logo, NavbarContainer } from './styles'

export default function Navbar() {
    return (
        <NavbarContainer>
            <Container>
                <Link to="/" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Logo src={logo} alt="meunovousado" />
                </Link>

                <ul>
                    <Link to="/lojas"><li>Lojas</li></Link>
                    <Link to="/veiculos"><li>Veículos</li></Link>
                </ul>
            </Container>
        </NavbarContainer>
    )
}
