import React, { useState } from 'react'

import jwt from 'jsonwebtoken'
import api from '../../services/api'

import { Link } from 'react-router-dom'

import { toast } from 'react-toastify'

import { useStyles } from './styles'

import Skeleton from '@material-ui/lab/Skeleton'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    IconButton,
    Grid,
    Tooltip,
    Hidden,
    Typography,
} from '@material-ui/core'

import ModalEditCar from '../Modals/ModalEditCar'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import { BiPin } from 'react-icons/bi'

import { generateVehicleURLString } from '../../utils/helpers/formatters/formatVehicleURL'

import noVehicleImage from '../../assets/images/noCars.jpeg'
import noVehiclesFound from '../../assets/images/noCarsFound.svg'

export default function ListVehiclesDashboard({
    shopVehicles,
    loadingVehicles,
}) {
    const classes = useStyles()

    const [vehicleData, setVehicleData] = useState({})
    const [modalEditCarOpen, setModalEditCarOpen] = useState(false)

    const toggleModal = (vehicleObject) => {
        setModalEditCarOpen(!modalEditCarOpen)
        setVehicleData(vehicleObject)
    }

    async function sellVehicle(vehicle) {
        const { REACT_APP_JWT_SECRET_KEY } = process.env

        const jwtToken = localStorage.getItem('token')
        const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)
    
        const { id: shopId } = jwtPayload

        const { 
            id,
            brand,
            model,
            year,
            price,
            investment,
            doors,
            color,
            license_plate,
            milage, 
            accessories,
            fuel_type      
        } = vehicle

        const soldVehicle = {
            brand,
            model,
            category: 'carros',
            year,
            price,
            investment,
            doors,
            color,
            fuel_type,
            license_plate,
            milage,
            accessories,
        }

        api.post(`/shop/${shopId}/reports`, soldVehicle)
            .then(() => {
                api.delete(`vehicles/${id}`).then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 400)
                })
            })
            .catch((err) => {
                toast.error('Algo aconteceu. Tente novamente!')
            })

        toast.success(
            'Veículo definido como vendido! Veja o relatório de vendas da aba estatísticas.'
        )
    }

    return (
        <>
            <>
                {modalEditCarOpen && (
                    <ModalEditCar
                        isOpen={modalEditCarOpen}
                        vehicleData={vehicleData}
                        toggleModal={toggleModal}
                    />
                )}

                {loadingVehicles ? (
                    <>
                        <Grid container spacing={12} style={{ marginTop: 20 }}>
                            <Grid item sm={12}>
                                <Skeleton
                                    variant="rect"
                                    style={{
                                        width: '100%',
                                        height: 15,
                                        borderRadius: 5,
                                        marginTop: 20,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    style={{
                                        width: '100%',
                                        height: 15,
                                        borderRadius: 5,
                                        marginTop: 20,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    style={{
                                        width: '100%',
                                        height: 15,
                                        borderRadius: 5,
                                        marginTop: 20,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : shopVehicles.length > 0 ? (
                    <TableContainer
                        component={Paper}
                        style={{
                            marginTop: 30,
                            marginBottom: 50,
                            borderBottom: 'none',
                            boxShadow:
                                'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                        }}
                        variant="outlined"
                    >
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableBody>
                                {shopVehicles.map((vehicle) => {
                                    return (
                                        <TableRow
                                            key={vehicle.model}
                                            className={classes.tableRow}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <img
                                                    src={
                                                        vehicle.image_url ||
                                                        vehicle.image_url !== ''
                                                            ? vehicle.image_url
                                                            : noVehicleImage
                                                    }
                                                    width="70"
                                                    height="50"
                                                    className={classes.carImage}
                                                    alt={vehicle.model}
                                                />
                                            </TableCell>

                                            <Hidden smDown>
                                                <TableCell align="left">
                                                    <p style={{ fontSize: 17 }}>
                                                        {vehicle.brand}
                                                    </p>
                                                </TableCell>
                                            </Hidden>

                                            <TableCell align="left">
                                                <Tooltip
                                                    title={vehicle.model}
                                                    placement="top"
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: 17,
                                                        }}
                                                    >
                                                        {String(
                                                            vehicle.model
                                                        ).slice(0, 20) + '...'}
                                                    </p>
                                                </Tooltip>
                                            </TableCell>

                                            <Hidden smDown>
                                                <TableCell align="left">
                                                    <b style={{ fontSize: 17 }}>
                                                        {'R$ ' +
                                                            vehicle.price.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}
                                                    </b>
                                                </TableCell>
                                            </Hidden>

                                            <TableCell align="right">
                                                <Tooltip
                                                    title="Marcar como vendido"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            sellVehicle(vehicle)
                                                        }
                                                    >
                                                        <BiPin />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell align="right">
                                                <Tooltip
                                                    title="Editar"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            toggleModal(vehicle)
                                                        }
                                                    >
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                            <Hidden smDown>
                                                <TableCell align="right">
                                                    <Link
                                                        to={generateVehicleURLString(
                                                            vehicle
                                                        )}
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                        }}
                                                        target="_blank"
                                                    >
                                                        <Tooltip
                                                            title="Visualizar"
                                                            placement="top"
                                                        >
                                                            <IconButton aria-label="view">
                                                                <VisibilityOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Link>
                                                </TableCell>
                                            </Hidden>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={noVehiclesFound}
                                width="300"
                                style={{ marginTop: 60 }}
                            />
                        </div>

                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#969696',
                                fontSize: 20,
                                width: '100%',
                                textAlign: 'center',
                                marginTop: 10,
                            }}
                        >
                            Ainda não há veículos no estoque
                        </Typography>
                    </>
                )}
            </>
        </>
    )
}
