import React, { useState, useEffect } from 'react'

import api from '../../../services/api'
import { logout } from '../../../services/auth'
import jwt from 'jsonwebtoken'

import { Link } from 'react-router-dom'

import clsx from 'clsx'

import { useTheme } from '@material-ui/core/styles'

import {
    Drawer,
    AppBar,
    Avatar,
    Toolbar,
    Tooltip,
    List,
    CssBaseline,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

import logo from '../../../assets/images/logo.png'

import { useStyles } from './styles'

import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'

export default function NavbarDashboard() {
    const classes = useStyles()
    const theme = useTheme()

    const { REACT_APP_JWT_SECRET_KEY } = process.env

    const jwtToken = localStorage.getItem('token')
    const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

    const { id: userId } = jwtPayload

    const [open, setOpen] = useState(false)

    const [isAdmin, setIsAdmin] = useState(false)
    const [shopName, setShopName] = useState('')
    const [profilePicture, setProfilePicture] = useState(null)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        async function loadShopData() {
            const response = await api.get(`shop/${userId}`)

            setIsAdmin(response.data.is_admin)
            setShopName(response.data.shop_name)
            setProfilePicture(response.data.profile_picture_path)
        }

        if (userId) {
            loadShopData()
        }
    }, [userId])

    return (
        <div className={classes.root}>
            <CssBaseline />

            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography variant="h6" noWrap>
                            <Link to="/dashboard" className={classes.link}>
                                <img
                                    src={logo}
                                    width="50"
                                    style={{ marginRight: 10 }}
                                    alt="logo"
                                />{' '}
                                Administrativo
                            </Link>
                        </Typography>

                        <Tooltip title={shopName}>
                            <Avatar
                                alt={shopName}
                                style={{ border: '1px #E0E0E0 solid' }}
                                src={profilePicture}
                            />
                        </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </div>

                <Divider />

                <List>
                    <Link
                        to="/"
                        style={{ textDecoration: 'none', color: '#3D3D3D' }}
                    >
                        <ListItem button key="Página Inicial">
                            <ListItemIcon>
                                <HomeOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Página Inicial" />
                        </ListItem>
                    </Link>

                    <Link
                        to="/dashboard"
                        style={{ textDecoration: 'none', color: '#3D3D3D' }}
                    >
                        <ListItem button key="Meus Veículos">
                            <ListItemIcon>
                                <DriveEtaOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Meus Veículos" />
                        </ListItem>
                    </Link>

                    <Link
                        to="/dashboard/estatisticas"
                        style={{ textDecoration: 'none', color: '#3D3D3D' }}
                    >
                        <ListItem button key="Estatísticas">
                            <ListItemIcon>
                                <AssessmentOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Estatísticas" />
                        </ListItem>
                    </Link>
                </List>

                <Divider />

                <List>
                    <Link
                        to="/dashboard/conta"
                        style={{ textDecoration: 'none', color: '#3D3D3D' }}
                    >
                        <ListItem button key="Conta">
                            <ListItemIcon>
                                <AccountCircleOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Conta" />
                        </ListItem>
                    </Link>

                    {isAdmin ? (
                        <Link
                            to="/dashboard/contas"
                            style={{ textDecoration: 'none', color: '#3D3D3D' }}
                        >
                            <ListItem button key="Contas">
                                <ListItemIcon>
                                    <SupervisorAccountOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contas" />
                            </ListItem>
                        </Link>
                    ) : null}

                    <Link
                        to="/dashboard/configuracoes"
                        style={{ textDecoration: 'none', color: '#3D3D3D' }}
                    >
                        <ListItem button key="Configurações">
                            <ListItemIcon>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Configurações" />
                        </ListItem>
                    </Link>
                </List>

                <Divider />

                <Link
                    to="/"
                    style={{ textDecoration: 'none', color: '#3D3D3D' }}
                >
                    <List>
                        <ListItem button key="Sair" onClick={() => logout()}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sair" />
                        </ListItem>
                    </List>
                </Link>
            </Drawer>
        </div>
    )
}
