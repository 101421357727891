import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import jwt from 'jsonwebtoken'
import { Helmet } from 'react-helmet'

import { ToastContainer } from 'react-toastify'

import ReactGA from 'react-ga'

import { useStyles } from './styles'

import { Button, Container, Hidden } from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import NavbarDashboard from '../../components/Navbars/NavbarDashboard'
import ModalAddCar from '../../components/Modals/ModalAddCar'
import ListVehiclesDashboard from '../../components/ListVehiclesDashboard'

import sunSvg from '../../assets/images/sun.svg'
import moonSvg from '../../assets/images/moon.svg'

export default function Dashboard() {
    const classes = useStyles()

    const today = new Date()
    const currentHour = today.getHours()

    const { REACT_APP_JWT_SECRET_KEY } = process.env

    const jwtToken = localStorage.getItem('token')
    const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

    const { id: shopId } = jwtPayload

    const [shopVehicles, setShopVehicles] = useState([])
    const [shopData, setShopData] = useState({})
    const [modalAddCarOpen, setModalAddCarOpen] = useState(false)
    const [loadingVehicles, setLoadingVehicles] = useState(true)

    const toggleModal = () => {
        setModalAddCarOpen(!modalAddCarOpen)
    }

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        async function loadVehicles() {
            const response = await api.get(`shop/${shopId}/vehicles`)

            setShopVehicles(response.data)
            setLoadingVehicles(false)
        }

        async function loadShopData() {
            const response = await api.get(`shop/${shopId}`)

            setShopData(response.data)
        }

        if (shopId) {
            Promise.all([loadVehicles(), loadShopData()])
        }
    }, [shopId])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Meus veículos | meunovousado.com.br</title>
            </Helmet>

            <NavbarDashboard />

            <ToastContainer autoClose={3000} />

            <Container maxWidth="md" className={classes.container}>
                <div className={classes.welcomeMessageContainer}>
                    <div>
                        {shopData.owner_full_name ? (
                            <>
                                <p className={classes.welcomeTitle}>
                                    Bem vindo(a) de volta,{' '}
                                    <b>
                                        {
                                            String(
                                                shopData.owner_full_name
                                            ).split(' ')[0]
                                        }
                                    </b>
                                    !
                                </p>
                                <p className={classes.welcomeSubtitle}>
                                    A{' '}
                                    {currentHour > 18
                                        ? 'noite'
                                        : currentHour < 12
                                        ? 'manha'
                                        : 'tarde'}{' '}
                                    está agradavel, que tal adicionar um <br />
                                    novo veículo em seu estoque?
                                </p>
                            </>
                        ) : (
                            <>
                                <Skeleton
                                    variant="rect"
                                    style={{ width: '100vw', height: 15 }}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 20,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    style={{ width: '100vw', height: 15 }}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 20,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    style={{ width: '100vw', height: 15 }}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 20,
                                    }}
                                />
                            </>
                        )}

                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: '#dc2626',
                                marginTop: 20,
                                marginBottom: 20,
                                fontSize: 13,
                            }}
                            color="primary"
                            onClick={toggleModal}
                            disableElevation
                        >
                            Adicionar veículo
                        </Button>
                    </div>

                    <Hidden smDown>
                        {currentHour > 18 ? (
                            <img src={moonSvg} width="200" height="200" />
                        ) : (
                            <img src={sunSvg} width="200" height="200" />
                        )}
                    </Hidden>
                </div>

                <ModalAddCar
                    isOpen={modalAddCarOpen}
                    toggleModal={toggleModal}
                />

                <ListVehiclesDashboard
                    shopVehicles={shopVehicles}
                    loadingVehicles={loadingVehicles}
                />
            </Container>
        </>
    )
}
