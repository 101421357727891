import React from 'react'
import CookieConsent from "react-cookie-consent"

import cookieIcon from '../../assets/images/cookie.svg'

export default function CookieConsentComponent() {
    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText="Concordo!"
                cookieName="cookieConsentComponent"
                style={{ background: "#fff", boxShadow: '0px 0px 2px #CDCDCD', color: '#878787', textAlign: 'center' }}
                buttonStyle={{ background: "#dc2626", color: '#fff', borderRadius: 5, fontSize: 16, padding: 10 }}
                expires={150}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={cookieIcon} alt="Cookie" width={40} />
                    <p style={{ marginLeft: 12, textAlign: 'left', fontSize: 17 }}>Para lhe fornecer uma melhor experiência, nós do meunovousado.com.br utilizamos cookies.</p>
                </div>
            </CookieConsent>
        </>
    )
}
