import React from 'react'

import { Link } from 'react-router-dom'

import { Typography, Paper } from '@material-ui/core'

import { useStyles } from './styles'

function VehicleShopDetails({ vehicle }) {
    const { shop } = vehicle

    const classes = useStyles()

    return (
        <Paper
            className={classes.paper}
            style={{ border: 'unset', marginTop: 10 }}
            variant="outlined"
        >
            <Link
                to={`/loja/${
                    shop ? shop.shop_name.replace(' ', '-').toLowerCase() : ''
                }/${shop ? shop.id : ''}`}
                style={{ textDecoration: 'none' }}
            >
                <img
                    className={classes.shopLogo}
                    src={shop ? shop.profile_picture_path : null}
                    alt={shop ? shop.shop_name : ''}
                />
            </Link>

            <div>
                <Link
                    to={`/loja/${
                        shop
                            ? shop.shop_name.replace(' ', '-').toLowerCase()
                            : ''
                    }/${shop ? shop.id : ''}`}
                    style={{ textDecoration: 'none', color: '#A5A5A5' }}
                >
                    <Typography
                        variant="p"
                        component="p"
                        gutterBottom
                        style={{ marginTop: 10 }}
                    >
                        {shop && shop.shop_name}{' '}
                    </Typography>
                </Link>

                <Typography
                    variant="p"
                    component="p"
                    gutterBottom
                    style={{ marginTop: 10 }}
                >
                    {shop && (
                        <span className={classes.alertBadge}>Anuciante</span>
                    )}{' '}
                </Typography>
            </div>
        </Paper>
    )
}

export default VehicleShopDetails
