export const colors = [
    'Preto',
    'Branco',
    'Prata',
    'Vermelho',
    'Cinza',
    'Azul',
    'Roxo',
    'Violeta',
    'Amarelo',
    'Verde',
    'Laranja',
    'Marrom',
    'Outra',
]
