import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { formatPrice } from '../../utils/helpers/formatters/formatPrice'
import { formatPhone } from '../../utils/helpers/formatters/formatPhone'

import clsx from 'clsx'

import {
    Button,
    Typography,
    Grid,
    Paper,
    Divider,
    Collapse,
    IconButton,
    Hidden,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import VehicleSugestions from '../../components/VehicleSugestions'
import VehicleDetails from '../../components/VehicleDetails'
import VehicleShopDetails from '../../components/VehicleShopDetails'
import VehicleAccessories from '../../components/VehicleAccessories'
import VehicleCarousel from '../../components/VehicleCarousel'
import Navbar from '../../components/Navbars/Navbar'

import {
    Container,
    VehicleDetailsOptions,
    NoVehicle,
    BottomMenu,
    useStyles,
} from './styles'

import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import noVehicleIcon from '../../assets/images/noVehicle.svg'

export default function Vehicle(props) {
    const { match } = props
    const vehicleId = String(match.params.vehicleId)

    const classes = useStyles()

    const [vehicle, setVehicle] = useState({})
    const [loading, setLoading] = useState(true)
    const [details, setDetails] = useState(true)

    const [expanded, setExpanded] = React.useState(false)

    function handleExpandClick() {
        setExpanded(!expanded)
    }

    useEffect(() => {
        if (String(vehicleId) === 'undefined') {
            props.history.push('/')
        }

        window.scrollTo(0, 0)

        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        function loadVehicle() {
            api.get(`vehicle/${vehicleId}`)
                .then((response) => {
                    setVehicle(response.data)
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                })
        }

        loadVehicle()
    }, [props.history, vehicleId])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {vehicle.brand
                        ? `${vehicle.brand} ${vehicle.model}`
                        : `Meu Novo Usado | Veja como é simples encontrar seu próximo veículo`}
                </title>
                <meta
                    name="description"
                    content={`Melhores preços da região em ${vehicle.brand} ${vehicle.model} usados!`}
                ></meta>
            </Helmet>

            <Navbar />

            <Container>
                {(!loading && Object.keys(vehicle).length <= 0) ||
                isNaN(Number(vehicleId)) ? (
                    <NoVehicle>
                        <img src={noVehicleIcon} width={350} />
                        <p>
                            Ops! Parece que esse veículo não está cadastrado ou
                            já foi vendido.
                        </p>
                        <small>
                            Você pode encontrar outras opções em nossa{' '}
                            {
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        fontWeight: '600',
                                        color: 'gray',
                                    }}
                                    to="/veiculos"
                                >
                                    guia de veículos
                                </Link>
                            }
                            !
                        </small>
                    </NoVehicle>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={7}>
                            {loading ? (
                                <Skeleton
                                    variant="rect"
                                    height={300}
                                    style={{ borderRadius: 5, width: '100%' }}
                                />
                            ) : (
                                <VehicleCarousel vehicle={vehicle} />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <Paper className={classes.paper} variant="outlined">
                                {loading ? (
                                    <Skeleton
                                        variant="rect"
                                        height={10}
                                        style={{
                                            borderRadius: 5,
                                            marginTop: 8,
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        variant="strong"
                                        component="strong"
                                        gutterBottom
                                        style={{ fontSize: 18 }}
                                    >
                                        {vehicle.brand}

                                        <Typography
                                            variant="strong"
                                            component="strong"
                                            gutterBottom
                                            style={{
                                                marginLeft: 10,
                                                backgroundColor: '#dc2626',
                                                color: '#fff',
                                                borderRadius: 5,
                                                fontSize: 18,
                                                padding: 5,
                                            }}
                                        >
                                            {vehicle.price
                                                ? formatPrice(vehicle.price)
                                                : null}
                                        </Typography>
                                    </Typography>
                                )}

                                {loading ? (
                                    <Skeleton
                                        variant="rect"
                                        height={10}
                                        style={{
                                            borderRadius: 5,
                                            marginTop: 8,
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        gutterBottom
                                        style={{ marginTop: 10 }}
                                    >
                                        {vehicle.model}
                                    </Typography>
                                )}

                                <Grid
                                    container
                                    spacing={3}
                                    style={{ marginTop: 10 }}
                                >
                                    <Grid item xs={12}>
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded,
                                            })}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="ver mais"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>

                                        <Collapse
                                            in={expanded}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <pre
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    whiteSpace: 'pre-wrap',
                                                    marginTop: 20,
                                                }}
                                            >
                                                {vehicle.description}
                                            </pre>
                                        </Collapse>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginTop: 5 }}>
                                        <Divider
                                            variant="fullWidth"
                                            style={{ width: '15%' }}
                                        />
                                    </Grid>

                                    {details ? (
                                        <VehicleDetails vehicle={vehicle} />
                                    ) : (
                                        <VehicleAccessories vehicle={vehicle} />
                                    )}
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: 5 }}>
                                    <Divider
                                        variant="fullWidth"
                                        style={{ width: '15%' }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    spacing={3}
                                    style={{
                                        padding: 10,
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                >
                                    <VehicleDetailsOptions>
                                        <li
                                            onClick={() => setDetails(true)}
                                            style={{
                                                color: details
                                                    ? '#dc2626'
                                                    : 'gray',
                                            }}
                                        >
                                            Detalhes
                                        </li>

                                        <li
                                            onClick={() => setDetails(false)}
                                            style={{
                                                color: !details
                                                    ? '#dc2626'
                                                    : 'gray',
                                            }}
                                        >
                                            Acessórios
                                        </li>
                                    </VehicleDetailsOptions>
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: 5 }}>
                                    <Divider
                                        variant="fullWidth"
                                        style={{ width: '15%' }}
                                    />
                                </Grid>

                                <VehicleShopDetails vehicle={vehicle} />

                                <Hidden smDown>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            backgroundColor: '#dc2626',
                                            padding: 10,
                                            paddingRight: 20,
                                            paddingLeft: 20,
                                            marginTop: 15,
                                            color: '#fff',
                                        }}
                                        disableElevation
                                        onClick={() =>
                                            window.open(
                                                `https://wa.me/55${formatPhone(
                                                    vehicle.shop.phone_number
                                                )}/?text=Tenho%20interesse%20no%20carro%20anunciado%20no%20meunovousado.com.br.`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        <p
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <WhatsAppIcon
                                                style={{ marginRight: 7 }}
                                            />{' '}
                                            Chamar no Whatsapp
                                        </p>
                                    </Button>
                                </Hidden>
                            </Paper>

                            {/* <VehicleShopDetails vehicle={vehicle} /> */}
                        </Grid>

                        <Grid item xs={12}>
                            {loading ? null : (
                                <VehicleSugestions
                                    {...props}
                                    currentVehicle={vehicle}
                                    currentBrand={vehicle.brand}
                                />
                            )}
                        </Grid>

                        <Hidden smUp>
                            <BottomMenu>
                                {' '}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        backgroundColor: '#dc2626',
                                        padding: 10,
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                        color: '#fff',
                                    }}
                                    disableElevation
                                    onClick={() =>
                                        window.open(
                                            `https://wa.me/55${formatPhone(
                                                vehicle.shop.phone_number
                                            )}/?text=Tenho%20interesse%20no%20carro%20anunciado%20no%20meunovousado.com.br.`,
                                            '_blank'
                                        )
                                    }
                                >
                                    <p
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <WhatsAppIcon
                                            style={{ marginRight: 7 }}
                                        />{' '}
                                        Chamar no Whatsapp
                                    </p>
                                </Button>
                            </BottomMenu>
                        </Hidden>
                    </Grid>
                )}
            </Container>
        </>
    )
}
