import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'unset',
        display: 'flex',
        justifyContent: 'center',
    },
    shopLogo: {
        width: '60px',
        height: '60px  ',
        borderRadius: '50%',
        marginRight: '30px',
        objectFit: 'cover',
        background: '#D6D6D6',
    },
    alertBadge: {
        backgroundColor: '#F0F0F0',
        color: '#A5A5A5',
        padding: 5,
        borderRadius: 5,
        fontSize: 12,
    },
}))
