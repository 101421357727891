import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 0px 2px #CDCDCD;
    max-width: 25%;
    height: 100%;
    margin: 0 auto;
    margin-top: 180px;
    padding-top: 20px;
    padding-bottom: 30px;
    color: #878787;

    @media (max-width: 1440px) {
        max-width: 40%;
        margin-top: 120px;
    }

    @media (max-width: 1024px) {
        max-width: 50%;
        margin-top: 100px;
    }

    @media (max-width: 1000px) {
        background-color: transparent;
        max-width: 60%;
    }

    @media (max-width: 700px) {
        max-width: 100%;
        box-shadow: unset;
    }

    p {
        margin-top: 30px;
        margin-bottom: 10px;
    }
`

export const Logo = styled.img`
    width: 120px;
`

export const Input = styled.input`
        border: none;
        box-shadow: 0px 0px 2px #CDCDCD;
        font-size: 12pt;
        padding: 15px;
        border-radius: 5px;
        width: 70%;
        margin-top: 30px;
        
        @media (max-width: 700px) {
            width: 80%;
        }

        &::placeholder {
            color: #949494;
        }
`

export const Button = styled.button`
        background-color: #dc2626;
        color: #fff;
        width: 70%;
        text-align: center;
        border: none;
        border-radius: 5px;
        padding: 13px;
        font-size: 13pt;
        margin-top: 40px;

        @media (max-width: 700px) {
            width: 80%;
        }

        &:hover {
            cursor: pointer;
            transition: 0.2s background;
            background-color: #b91c1c;
        }
`

export const ButtonLoading = styled.button`
        background-color: #DEDEDE;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #898989;
        width: 70%;
        text-align: center;
        border: none;
        border-radius: 5px;
        padding: 13px;
        font-size: 13pt;
        margin-top: 40px;

        @media (max-width: 700px) {
            width: 80%;
        }
`

export const SignUpRedirect = styled(Link)`
    color: #dc2626;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        transition: 0.2s color;
        color: #b91c1c;
    }
`