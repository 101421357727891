import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paperIcon: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 13,
        backgroundColor: '#fff', 
        boxShadow: 'unset'
    },
    paperIconImg: {
        width: 32,
        height: 32,
        marginBottom: 10
    },
}))