import React, { useState } from 'react'

import api from '../../../services/api'
import jwt from 'jsonwebtoken'

import { toast } from 'react-toastify'

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@material-ui/core'

export default function ModalAddShop({ isOpen, toggleModal }) {
    const { REACT_APP_JWT_SECRET_KEY } = process.env

    const jwtToken = localStorage.getItem('token')
    const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

    const { id: shopId } = jwtPayload

    const [buttonAddIsLoading, setButtonAddIsLoading] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    async function storePartner() {
        setButtonAddIsLoading(true)

        const partnerData = {
            name,
            email,
            phone_number: phone
        }

        await api.post(`shop/${shopId}/partners`, partnerData)

        toast.success('Sócio adicionado com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 800)
    }

    return (
        <Dialog disableEscapeKeyDown open={isOpen} onClose={toggleModal} maxWidth="md" fullWidth={true}>
            <DialogTitle>Informações do sócio</DialogTitle>
            <DialogContent>
                <form noValidate autoComplete="off" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 14 }}>
                    <TextField
                        id="outlined-select-currency"
                        label="Nome do sócio"
                        type="text"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        onKeyPress={e => e.key === 'Enter' ? e.preventDefault() : null}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                    </TextField>
                </form>

                <form noValidate autoComplete="off" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 14 }}>
                    <TextField
                        id="outlined-select-currency"
                        label="E-mail"
                        type="text"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        onKeyPress={e => e.key === 'Enter' ? e.preventDefault() : null}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                    </TextField>
                </form>

                <form noValidate autoComplete="off" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 14 }}>
                    <TextField
                        id="outlined-select-currency"
                        label="Telefone"
                        type="text"
                        value={phone}
                        onChange={event => setPhone(event.target.value)}
                        onKeyPress={e => e.key === 'Enter' ? e.preventDefault() : null}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                    </TextField>
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={toggleModal} color="primary" style={{ color: '#969696' }}>
                    Cancelar
                </Button>
                {
                    buttonAddIsLoading
                        ?
                        <Button variant="contained" color="primary" style={{ backgroundColor: '#E3E3E3' }} disableElevation disabled>
                            Adicionando...
                        </Button>
                        :
                        <Button variant="contained" onClick={() => storePartner()} color="primary" style={{ backgroundColor: '#dc2626' }} disableElevation>
                            Adicionar
                        </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
