import React from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'

import Home from './pages/Home'
import Loja from './pages/Shop'
import Lojas from './pages/Shops'
import SignIn from './pages/SignIn'

import Vehicle from './pages/Vehicle'
import Vehicles from './pages/Vehicles'
import Administrative from './pages/Administrative'

import Dashboard from './pages/Dashboard'
import Profile from './pages/Dashboard/Profile'
import Analytics from './pages/Dashboard/Analytics'
import Accounts from './pages/Dashboard/Accounts'
import Settings from './pages/Dashboard/Settings'

import PrivateRoute from './components/PrivateRoute'

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" exact component={Home} />
                <Route path="/loja/:shopName?/:shopId?" component={Loja} />
                <Route path="/lojas" component={Lojas} />
                <Route path="/signin" component={SignIn} />
                <Route
                    exact
                    path="/veiculo/:vehicleModel/:vehicleId"
                    component={Vehicle}
                />
                <Route path="/veiculos/:searchParams?" component={Vehicles} />
                <Route path="/administrativo" component={Administrative} />

                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute
                    exact
                    path="/dashboard/conta"
                    component={Profile}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/contas"
                    component={Accounts}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/estatisticas"
                    component={Analytics}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/configuracoes"
                    component={Settings}
                />

                <Redirect from="*" to="/" />
            </Switch>
        </BrowserRouter>
    )
}
