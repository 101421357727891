import React from 'react'

import Carousel from 'react-material-ui-carousel'

import { CarouselImage } from './styles'

export default function VehicleCarousel({ vehicle }) {
    return (
        <>
            <Carousel
                className="Imagens"
                autoPlay={2000}
                timer={2000}
                animation="fade"
                indicators={true}
                timeout={0}
                navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={false}
            >
                {vehicle.images.map((image) => (
                    <CarouselImage
                        src={image.url}
                        alt={vehicle.model}
                        key={image.url}
                    />
                ))}
            </Carousel>
        </>
    )
}
