import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    inputRoot: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF5A5F"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF5A5F"
        }
    }
}))