import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import { Helmet } from 'react-helmet'
import { Adsense } from '@ctrl/react-adsense'

import { BsSearch } from 'react-icons/bs'
import { SearchBar, Input, Button } from './styles'

import ReactGA from 'react-ga'

import CircularProgress from '@material-ui/core/CircularProgress'

import Navbar from '../../components/Navbars/Navbar'
import ListVehicles from '../../components/ListVehicles'

export default function Vehicles(props) {
    const { match } = props

    const searchParams = String(match.params.searchParams)

    const [vehicles, setVehicles] = useState(0)
    const [loading, setLoading] = useState(true)
    const [searchParamVehicle, setSearchParamVehicle] = useState('')

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        if (searchParams === 'undefined') {
            loadVehicles()
        } else {
            loadSearchVehicles()
        }
    }, [])

    async function loadVehicles() {
        const response = await api.get('vehicles')

        setVehicles(response.data)
        setLoading(false)
    }

    async function loadSearchVehicles() {
        setLoading(true)

        const search =
            searchParamVehicle.length > 0 ? searchParamVehicle : searchParams

        if (search === 'undefined') {
            setLoading(false)
            return false
        }

        const response = await api.post('search/vehicles', {
            searchParams: search,
        })

        setVehicles(response.data)
        setLoading(false)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Veículos | meunovousado.com.br</title>
                <meta
                    name="description"
                    content="Encontre carros usados em Criciúma
                     e região"
                ></meta>
                <meta
                    name="keywords"
                    content="carros usados, carros em criciúma e região, carros usados a venda, carros a venda"
                ></meta>
            </Helmet>

            <Navbar />

            <SearchBar>
                <p style={{ color: '#797979', fontSize: 20 }}>
                    Veículos encontrados <br />
                    <small>
                        {loading ? (
                            <CircularProgress
                                size={15}
                                color="#949494"
                                value={10}
                            />
                        ) : (
                            `${
                                vehicles.length > 0
                                    ? `${vehicles.length} resultados`
                                    : 'Nenhum resultado encontrado'
                            }`
                        )}
                    </small>
                </p>

                <Input
                    placeholder="Procurar por um veículo específico..."
                    value={
                        searchParamVehicle.length > 0
                            ? searchParamVehicle
                            : null
                    }
                    onChange={(e) => {
                        setSearchParamVehicle(e.target.value)
                    }}
                    onKeyPress={(e) =>
                        e.key == 'Enter' ? loadSearchVehicles() : null
                    }
                />

                <Button onClick={() => loadSearchVehicles()}>
                    <BsSearch fill="white" style={{ marginRight: '10px' }} />
                    Buscar
                </Button>
            </SearchBar>

            <ListVehicles withContainer vehicles={vehicles} loading={loading} />

            <Adsense
                client="ca-pub-2071790119973662"
                slot="5524198658"
                style={{ display: 'block' }}
                layout="in-article"
                format="auto"
            />
        </>
    )
}
