import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'unset',
        // boxShadow:
    //     'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
    },
    inputDescription: {
        width: '100%',
        border: 'none',
        borderColor: '#fff',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}))

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 60%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 80px;
    color: #878787;

    @media (max-width: 1440px) {
        max-width: 70%;
        margin-top: 30px;
    }

    @media (max-width: 1024px) {
        max-width: 95%;
        margin-top: 5px;
    }
`

export const CarInformation = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 25px;
    background-color: #fff;
    box-shadow: 0px 0px 1px #ccc;
    width: 45%;
    height: 500px;
    border-radius: 5px;

    h2 {
        color: #949494;
        font-size: 14pt;
        font-weight: normal;
    }

    h3 {
        color: #949494;
        font-size: 15pt;
        font-weight: normal;
    }

    strong {
        margin-top: 5px;
        font-size: 15pt;
    }

    small {
        margin-top: 20px;
        font-size: 11pt;
    }

    div {
        margin-top: 28px;
    }
`

export const CarImages = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    border-radius: 10px;

    div {
        background-color: #f8f8f8;
        width: 100%;
        max-height: 40%;
    }

    img {
        max-width: 100%;
        max-height: 10%;
        border-radius: 10px;
        box-shadow: 0px 0px 5px #ccc;
    }
`

export const VehicleDetailsOptions = styled.ul`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;

    li {
        text-decoration: none;
        list-style-type: none;
        padding: 13px;

        &:hover {
            transition: 0.2s color !important;
            color: #b91c1c !important;
            cursor: pointer;
        }
    }
`
export const NoVehicle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 40px;

    p {
        font-size: 14pt;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }

    small {
        text-align: center;
    }
`

export const BottomMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 3px #ccc;
`