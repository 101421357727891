import React, { useState, useEffect } from 'react'

import api from '../../../services/api'

import { toast } from 'react-toastify'

import { Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import ImageUploader from 'react-images-upload'

import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core'

import { useStyles } from './styles'

export default function ModalAddShop({ isOpen, toggleModal }) {
    const classes = useStyles()

    const [adding, setAdding] = useState(false)

    const [ownerName, setOwnerName] = useState('')
    const [ownerLastName, setOwnerLastName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [shopName, setShopName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [longitude, setLongitude] = useState('')
    const [latitude, setLatitude] = useState('')
    const [cpf, setCpf] = useState('')
    const [profilePictureURL, setProfilePictureURL] = useState('')

    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        async function loadStates() {
            const response = await api.get(
                'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
            )

            setStates(response.data)
        }

        async function loadCities() {
            const response = await api.get(
                'https://servicodados.ibge.gov.br/api/v1/localidades/estados/sc/distritos'
            )

            setCities(response.data)
        }

        loadStates()
        loadCities()
    }, [])

    async function storeShop() {
        setAdding(true)

        if (profilePictureURL.length <= 0) {
            setAdding(false)

            return toast.error('Você precisa adicionar uma imagem de perfil')
        }

        const shopData = {
            owner_firstname: ownerName,
            owner_lastname: ownerLastName,
            username,
            email,
            password,
            shop_name: shopName,
            phone_number: phoneNumber,
            zipcode,
            state,
            city,
            owner_cpf: cpf,
            active_account: true,
            profile_picture_path: profilePictureURL,
        }

        await api.post(`/users/`, shopData)

        toast.success('Loja criada com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const formImageSubmit = (image) => {
        const API_URL = 'https://gbcarros-server.herokuapp.com/'

        const formData = new FormData()
        formData.append('file', image)

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }

        api.post(`files`, formData, config)
            .then((resp) => {
                setProfilePictureURL(resp.data.url)
                toast.success('Imagem enviada com sucesso!')
            })
            .catch((err) => {
                toast.error('Falha ao enviar imagem! Tente novamente.')
            })
    }

    return (
        <Dialog
            disableEscapeKeyDown
            open={isOpen}
            onClose={toggleModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Informações da loja</DialogTitle>
            <DialogContent>
                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <ImageUploader
                        withIcon={false}
                        withPreview={true}
                        buttonText="Adicionar imagem"
                        onChange={(imageArray) =>
                            formImageSubmit(imageArray[0])
                        }
                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                        maxFileSize={5242880}
                        singleImage={true}
                        label=" "
                        buttonStyles={{
                            backgroundColor: '#dc2626',
                            borderRadius: 5,
                            padding: 10,
                        }}
                        fileContainerStyle={{ boxShadow: 'unset' }}
                    />
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Nome do proprietário"
                        type="text"
                        value={ownerName}
                        onChange={(event) => setOwnerName(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Sobrenome do proprietário"
                        type="text"
                        value={ownerLastName}
                        onChange={(event) =>
                            setOwnerLastName(event.target.value)
                        }
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Nome de usuário"
                        type="text"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="E-mail"
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Senha"
                        type="text"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Nome da loja"
                        type="text"
                        value={shopName}
                        onChange={(event) => setShopName(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Longitude"
                        type="text"
                        value={longitude}
                        onChange={(event) => setLongitude(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Latitude"
                        type="text"
                        value={latitude}
                        onChange={(event) => setLatitude(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="(48) 9-9999-9999"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="Telefone"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="99.999-999"
                        value={zipcode}
                        onChange={(event) => setZipcode(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="CEP"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <Autocomplete
                        id="states"
                        classes={classes}
                        options={states}
                        getOptionLabel={(state) => state.sigla}
                        onChange={(event, value) =>
                            setState(value.sigla ? value.sigla : '')
                        }
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Estado"
                                variant="outlined"
                            />
                        )}
                    />
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <Autocomplete
                        id="states"
                        classes={classes}
                        options={cities}
                        getOptionLabel={(city) => city.nome}
                        onChange={(event, value) =>
                            setCity(value.nome ? value.nome : '')
                        }
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Cidade"
                                variant="outlined"
                            />
                        )}
                    />
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="999.999.999-99"
                        value={cpf}
                        onChange={(event) => setCpf(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="CPF do proprietário"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={toggleModal}
                    color="primary"
                    style={{ color: '#969696' }}
                >
                    Cancelar
                </Button>
                {adding ? (
                    <Button
                        variant="contained"
                        onClick={() => storeShop()}
                        color="primary"
                        style={{ backgroundColor: '#E3E3E3' }}
                        disableElevation
                        disabled
                    >
                        Adicionar
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => storeShop()}
                        color="primary"
                        style={{ backgroundColor: '#dc2626' }}
                        disableElevation
                    >
                        Adicionar
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
