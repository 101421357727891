import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'unset',
        marginBottom: '10px'
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;

    a {
        cursor: pointer;
    }
`

export const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70vw;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 300px;

    @media (max-width: 700px) {
        width: 95vw;
    }

    p {
        color: #808080;
        font-size: 14pt;
    }

    small {
        text-align: center;
        color: #A7A7A7;
    }
`

export const Logo = styled.img`
    width: 100px;
`

export const Input = styled.input`
    border: none;
    box-shadow: 0px 0px 4px #ccc;
    font-size: 12pt;
    padding: 15px;
    padding-right: 200px;
    border-radius: 10px;
    width: 70%;
    margin-top: 30px;

    &::placeholder {
        color: #949494;
    }
`

export const Button = styled.button`
    background-color: #dc2626;
    color: #fff;
    width: 160px;
    text-align: center;
    border: none;
    border-radius: 5px;
    padding: 13px;
    font-size: 12pt;
    margin-right: 30px;
    cursor: pointer;
`
