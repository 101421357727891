import React, { useState, useEffect } from 'react'

import api from '../../../services/api'
import { Helmet } from 'react-helmet'

import { ToastContainer } from 'react-toastify'

import { Container } from '@material-ui/core/'

import NavbarDashboard from '../../../components/Navbars/NavbarDashboard'
import ModalAddShop from '../../../components/Modals/ModalAddShop'
import ModalEditShop from '../../../components/Modals/ModalEditShop'

import dashboardLogo from '../../../assets/images/dashboard.svg'

import { Paper, Typography, Button, IconButton } from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import { useStyles } from './styles'

export default function DashboardAccounts() {
    const classes = useStyles()

    const [shops, setShops] = useState([])
    const [shopData, setShopData] = useState({})
    const [loading, setLoading] = useState(true)
    const [modalAddShopOpen, setModalAddShopOpen] = useState(false)
    const [modalEditShopOpen, setModalEditShopOpen] = useState(false)

    useEffect(() => {
        loadShops()
    }, [])

    async function loadShops() {
        const shops = await api.get(`shops`)

        setShops(shops.data)
        setLoading(false)
    }

    const toggleModalAddShop = () => {
        setModalAddShopOpen(!modalAddShopOpen)
    }

    const toggleModalEditShop = (shop) => {
        setModalEditShopOpen(!modalEditShopOpen)
        setShopData(shop)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gerênciamento de lojas | meunovousado.com.br</title>
            </Helmet>

            <NavbarDashboard />

            <ToastContainer autoClose={3000} />

            <Container maxWidth="md" className={classes.container}>
                <img
                    src={dashboardLogo}
                    width="200"
                    height="200"
                    alt="Logo"
                    style={{ borderRadius: '50%' }}
                />

                <Typography
                    gutterBottom
                    variant="h6"
                    component="h6"
                    style={{ color: '#969696', marginBottom: 20 }}
                >
                    Gerêncie as lojas dos usuários
                </Typography>

                <Button
                    variant="contained"
                    style={{ backgroundColor: '#dc2626', marginBottom: 30 }}
                    color="primary"
                    onClick={toggleModalAddShop}
                    disableElevation
                >
                    Criar nova loja
                </Button>

                <TableContainer
                    component={Paper}
                    style={{ borderBottom: 'none' }}
                    variant="outlined"
                >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">Loja</TableCell>
                                <TableCell align="right">E-mail</TableCell>
                                <TableCell align="right">Região</TableCell>
                                <TableCell align="right">Opções</TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Skeleton
                                            variant="rect"
                                            style={{
                                                width: '100vw',
                                                height: 15,
                                            }}
                                            style={{
                                                borderRadius: 5,
                                                marginLeft: 10,
                                                marginTop: 20,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Skeleton
                                            variant="rect"
                                            style={{
                                                width: '100vw',
                                                height: 15,
                                            }}
                                            style={{
                                                borderRadius: 5,
                                                marginLeft: 10,
                                                marginTop: 20,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Skeleton
                                            variant="rect"
                                            style={{
                                                width: '100vw',
                                                height: 15,
                                            }}
                                            style={{
                                                borderRadius: 5,
                                                marginLeft: 10,
                                                marginTop: 20,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Skeleton
                                            variant="rect"
                                            style={{
                                                width: '100vw',
                                                height: 15,
                                            }}
                                            style={{
                                                borderRadius: 5,
                                                marginLeft: 10,
                                                marginTop: 20,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {shops.map((shop) => (
                                    <TableRow key={shop.id}>
                                        <TableCell component="th" scope="row">
                                            {shop.id}
                                        </TableCell>
                                        <TableCell align="right">
                                            {shop.shop_name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {shop.email}
                                        </TableCell>
                                        <TableCell align="right">
                                            {shop.city} ({shop.state})
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                aria-label="edit"
                                                onClick={() =>
                                                    toggleModalEditShop(shop)
                                                }
                                            >
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                <ModalAddShop
                    isOpen={modalAddShopOpen}
                    toggleModal={toggleModalAddShop}
                />

                {modalEditShopOpen && (
                    <ModalEditShop
                        isOpen={modalEditShopOpen}
                        toggleModal={toggleModalEditShop}
                        shopData={shopData}
                    />
                )}
            </Container>
        </>
    )
}
