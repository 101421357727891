import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 45px;
    color: #878787;

    @media (max-width: 1920px) {
        max-width: 60%;
    }

    @media (max-width: 1440px) {
        max-width: 70%;
    }

    @media (max-width: 1024px) {
        max-width: 95%;
    }

    @media (max-width: 500px) {
        margin-bottom: 40px;
        max-width: 95%;
    }
`

export const Title = styled.p`
    font-size: 15pt;
    color: #797979;
`

export const Vehicles = styled.p`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 25px;
`

export const Vehicle = styled.p`
    background-color: #fff;
    width: 230px;
    height: 240px;
    text-align: center;
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 7px;
    margin-bottom: 40px;
    margin-right: 15px;

    @media (max-width: 500px) {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 4px #ccc;
        transition: 0.2s box-shadow;
    }

    img {
        width: 100%;
        height: 60%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #F8F8F8;
    }
`
export const VehicleTitle = styled.p`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: #949494;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;

    p {
        font-size: 13pt;
    }

    span {
        margin-top: 4px;
        font-size: 12pt;
        font-weight: bold;
    }
`

export const VehicleRedirect = styled(Link)`
    text-decoration: none;
`

export const SeeMoreButton = styled.button`
    background-color: #dc2626;
    display: flex;
    color: #fff;
    height: 100%;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 5px;
    padding: 12px;
    font-size: 13pt;
    justify-content: center;
    margin-top: 15px;

    &:hover {
        transition: 0.1s background;
        background-color: #b91c1c;
        cursor: pointer;
    }
`