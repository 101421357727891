import React from 'react'

import { Link } from 'react-router-dom'

import { formatPrice } from '../../utils/helpers/formatters/formatPrice'

import { Container, Title, SeeMoreButton } from './styles'

import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Hidden,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import { generateVehicleURLString } from '../../utils/helpers/formatters/formatVehicleURL'

import noVehicleImage from '../../assets/images/noCars.jpeg'

export default function ListVehicles({
    loading,
    vehicles,
    withContainer,
    showTitle,
    title,
    limit,
    withMoreOffersButton,
    redirectPage,
}) {
    const formattedVehicles = limit ? vehicles.slice(0, limit) : vehicles
    const VehicleListGrid = () => (
        <Grid container spacing={1} style={{ marginTop: 15 }}>
            {loading ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Skeleton
                                variant="rect"
                                height={150}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 10,
                                    width: '80%',
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Skeleton
                                variant="rect"
                                width={220}
                                height={150}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 10,
                                    width: '80%',
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Skeleton
                                variant="rect"
                                width={220}
                                height={150}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 10,
                                    width: '80%',
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Skeleton
                                variant="rect"
                                width={220}
                                height={150}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 10,
                                    width: '80%',
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                            <Skeleton
                                variant="rect"
                                width={130}
                                height={10}
                                style={{
                                    borderRadius: 5,
                                    marginLeft: 10,
                                    marginTop: 8,
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                formattedVehicles &&
                formattedVehicles.map((vehicle) => {
                    const {
                        model,
                        price,
                        image_url,
                        id,
                        year,
                        milage,
                        shop,
                    } = vehicle

                    return (
                        <Grid item xs={6} sm={6} md={3} key={id}>
                            <Link
                                to={generateVehicleURLString(vehicle)}
                                style={{ textDecoration: 'none' }}
                            >
                                <Card
                                    style={{ height: 290 }}
                                    variant="outlined"
                                >
                                    <CardMedia
                                        component="img"
                                        alt="Contemplative Reptile"
                                        height="140"
                                        style={{ objectFit: 'cover' }}
                                        image={
                                            image_url || image_url !== ''
                                                ? image_url
                                                : noVehicleImage
                                        }
                                        title={model}
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h1"
                                            component="p"
                                            style={{
                                                color: '#363636',
                                                fontSize: 19,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {formatPrice(price)}

                                            <Typography
                                                gutterBottom
                                                variant="p"
                                                component="small"
                                                style={{
                                                    color: '#474747',
                                                    fontSize: 13,
                                                    marginTop: 3,
                                                }}
                                            >
                                                {year} |{' '}
                                                {milage.toLocaleString('pt-BR')}{' '}
                                                Km
                                            </Typography>
                                        </Typography>

                                        <Typography
                                            variant="h1"
                                            component="p"
                                            style={{
                                                fontSize: 12,
                                                color: '#6F6F6F',
                                                marginTop: 3,
                                                marginBottom: 7,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            {shop &&
                                                shop.city &&
                                                `${shop.city} (${shop.state})`}
                                        </Typography>

                                        <Typography
                                            variant="p"
                                            component="p"
                                            style={{
                                                fontSize: 13,
                                                color: '#6F6F6F',
                                            }}
                                        >
                                            {model}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )
                })
            )}
        </Grid>
    )

    return (
        <>
            {withContainer ? (
                <Container>
                    {showTitle ? <Title>{title}</Title> : null}
                    <VehicleListGrid />

                    {!loading && withMoreOffersButton && redirectPage ? (
                        <Hidden mdUp>
                            <SeeMoreButton
                                onClick={() => redirectPage('/veiculos')}
                            >
                                Ver mais ofertas
                            </SeeMoreButton>
                        </Hidden>
                    ) : null}
                </Container>
            ) : (
                <>
                    {showTitle ? <Title>{title}</Title> : null}
                    <VehicleListGrid />
                </>
            )}
        </>
    )
}
