import React from 'react'

import GlobalStyle from './styles/global'
import 'react-toastify/dist/ReactToastify.css'

import Routes from './routes'

function App() {
  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  )
}

export default App;
