import styled from 'styled-components'

export const CarouselImage = styled.img`
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 5px;

    @media (max-width: 1680px) {
        height: 400px;
    }

    @media (max-width: 1440px) {
        height: 400px;
    }

    @media (max-width: 1360px) {
        height: 400px;
    }

    @media (max-width: 800px) {
        height: 300px;
    }
`