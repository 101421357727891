import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
    color: #878787;

    @media (max-width: 1920px) {
        max-width: 60%;
    }

    @media (max-width: 1440px) {
        max-width: 70%;
    }

    @media (max-width: 1024px) {
        max-width: 95%;
    }

    @media (max-width: 500px) {
        max-width: 95%;
    }
`

export const GarageName = styled.p`
    font-size: 12pt;
    font-weight: 500;
    color: #6A6A6A;
    text-align: center;
    margin-bottom: 10px;
`

export const Title = styled.p`
    font-size: 15pt;
    color: #797979;
    margin-bottom: 25px;
`

export const Garages = styled.p`
    display: flex;
    justify-content: flex-start;

    @media (max-width: 500px) {
        flex-direction: column;
    }
`

export const Garage = styled.p`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px;
    height: 180px;
    text-align: center;
    box-shadow: 0px 0px 1px #ccc;
    border-radius: 6px;
    margin-right: 20px;

    @media (max-width: 500px) {
        width: 50%;
        margin-bottom: 1.5rem;
    }

    &:hover {
        cursor: pointer;
        transition: 0.1s box-shadow;
        box-shadow: 0px 0px 4px #ccc;
    }

    img {
        width: 50%;
        height: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
        border-radius: 50%;
    }
`

export const GarageRedirect = styled(Link)`
    text-decoration: none;
`