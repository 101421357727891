import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
    footerContainer: {
        background: 'rgb(253, 254, 254)',
        paddingTop: 15,
        boxShadow: '0px 0px 2px #CDCDCD',
    },
    footerHeaderLogo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerLogoTitle: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bolder',
        fontSize: '14pt',
        marginBottom: 0,
        color: '#949494',
    },
    footerLogoSubtitle: {
        color: '#949494',
        fontSize: 13,
        verticalAlign: 'top',
    },
    footerCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 6,
    },
    footerCopyRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        padding: 12,
        background: 'rgb(244, 246, 249)',
        color: '#949494',
        marginTop: 30,
        textAlign: 'center',
        fontSize: 15,
    },
    footerLink: {
        textDecoration: 'none',
        listStyleType: 'none',
        color: '#878787',
    },
    footerLinkTitle: {
        textAlign: 'left',
        color: '#878787',
        fontWeight: 'bold',
        marginBottom: 7,
    },
})
