import React, { useState, useEffect } from 'react'

import api from '../../../services/api'
import jwt from 'jsonwebtoken'
import { Helmet } from 'react-helmet'

import { ToastContainer, toast } from 'react-toastify'

import ImageUploader from 'react-images-upload'

import {
    Container,
    CircularProgress,
    Grid,
    Typography,
    Avatar,
} from '@material-ui/core/'

import NavbarDashboard from '../../../components/Navbars/NavbarDashboard'

import { useStyles, Input, Button, ButtonLoading } from './styles'

export default function DashboardProfile() {
    const classes = useStyles()

    const { REACT_APP_JWT_SECRET_KEY } = process.env

    const jwtToken = localStorage.getItem('token')
    const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

    const { id: shopId } = jwtPayload

    const [shop, setShop] = useState({})
    const [shopImageURL, setShopImageURL] = useState('')

    const [saving, setSaving] = useState(false)
    const [savingImage, setSavingImage] = useState(false)
    const [loading, setLoading] = useState(true)

    const [shopName, setShopName] = useState('')
    const [email, setEmail] = useState('')
    const [ownerName, setOwnerName] = useState('')
    const [phone, setPhone] = useState('')
    const [longitude, setLongitude] = useState('')
    const [latitude, setLatitude] = useState('')

    useEffect(() => {
        if (shopId) {
            loadShop()
        }
    }, [shopId])

    async function loadShop() {
        const shop = await api.get(`shop/${shopId}`)

        setShop(shop.data)
        setShopImageURL(shop.profile_picture_path)
        setShopName(shop.data.shop_name)
        setEmail(shop.data.email)
        setOwnerName(shop.data.owner_full_name)
        setPhone(shop.data.phone_number)

        setLongitude(shop.data.longitude)
        setLatitude(shop.data.latitude)

        setLoading(false)
    }

    async function updateShop() {
        const { is_admin } = shop

        setSaving(true)

        const shopData = {
            shop_name: shopName,
            email,
            owner_full_name: ownerName,
            phone_number: phone,
            longitude,
            latitude,
            is_admin,
        }

        await api.put(`shop/${shopId}`, shopData)

        toast.success('Dados salvos com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    async function updateShopImage(url) {
        await api.put(`shop/${shopId}`, { profile_picture_path: url })
    }

    const formImageSubmit = (image) => {
        setSavingImage(true)

        const API_URL = 'https://gbcarros-server.herokuapp.com/'

        const formData = new FormData()
        formData.append('file', image)

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        }

        api.post(`files`, formData, config)
            .then((resp) => {
                setShopImageURL(resp.data.url)
                updateShopImage(resp.data.url)

                toast.success('Imagem atualizada com sucesso!')

                setTimeout(() => {
                    window.location.reload()
                }, 500)
            })
            .catch((err) => {
                setSavingImage(false)

                toast.error('Falha ao enviar imagem! Tente novamente.')
            })
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Perfil | meunovousado.com.br</title>
            </Helmet>

            <NavbarDashboard />

            <ToastContainer autoClose={3000} />

            <Container maxWidth="md" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar
                                alt={shop.shop_name}
                                style={{
                                    width: 100,
                                    height: 100,
                                    marginTop: 60,
                                }}
                                src={
                                    shop.profile_picture_path
                                        ? shop.profile_picture_path
                                        : 'https://images.homedepot-static.com/productImages/fc91cb23-b6db-4d32-b02a-f1ed61dd39a8/svn/folkstone-matte-formica-laminate-sheets-009271258408000-64_400_compressed.jpg'
                                }
                            />

                            <ImageUploader
                                withIcon={false}
                                withPreview={false}
                                buttonText="Alterar foto"
                                onChange={(imageArray) =>
                                    formImageSubmit(imageArray[0])
                                }
                                imgExtension={[
                                    '.jpg',
                                    '.gif',
                                    '.png',
                                    '.gif',
                                    '.jpeg',
                                ]}
                                maxFileSize={5242880}
                                singleImage={true}
                                label=""
                                buttonStyles={{
                                    backgroundColor: '#dc2626',
                                    borderRadius: 5,
                                    padding: 10,
                                }}
                                fileContainerStyle={{
                                    boxShadow: 'unset',
                                    margin: '0 auto',
                                    padding: 0,
                                    width: 100,
                                    backgroundColor: 'transparent',
                                }}
                            />

                            {savingImage ? (
                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    style={{
                                        color: '#C1C1C1',
                                        fontSize: 16,
                                        margin: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CircularProgress
                                        color="#C1C1C1"
                                        size={20}
                                        style={{ marginRight: 10 }}
                                    />{' '}
                                    Enviando imagem...
                                </Typography>
                            ) : null}
                        </div>

                        <div style={{ marginLeft: 30 }}>
                            <Typography
                                gutterBottom
                                variant="p"
                                component="p"
                                style={{
                                    color: '#787878',
                                    fontSize: 20,
                                    margin: 0,
                                }}
                            >
                                {shop.shop_name}
                            </Typography>

                            <Typography
                                gutterBottom
                                variant="p"
                                component="p"
                                style={{
                                    color: '#C1C1C1',
                                    fontSize: '14px',
                                    margin: 0,
                                }}
                            >
                                {shop.email}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Nome da loja
                        </Typography>

                        <Input
                            placeholder={shopName}
                            value={shopName}
                            onChange={(e) => setShopName(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            E-mail
                        </Typography>

                        <Input
                            placeholder={email}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Nome do proprietário
                        </Typography>

                        <Input
                            placeholder={ownerName}
                            value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Celular da loja
                        </Typography>

                        <Input
                            placeholder={phone}
                            value={phone}
                            type="tel"
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Cidade
                        </Typography>

                        <Input
                            placeholder="Cidade"
                            value={shop.city}
                            style={{ color: '#ccc' }}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Estado
                        </Typography>

                        <Input
                            placeholder="Estado"
                            value={shop.state}
                            style={{ color: '#ccc' }}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Longitude
                        </Typography>

                        <Input
                            placeholder={
                                longitude
                                    ? longitude
                                    : 'Longitude ainda não informada'
                            }
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#787878',
                                fontSize: 15,
                                margin: 0,
                                paddingBottom: 8,
                            }}
                        >
                            Latitude
                        </Typography>

                        <Input
                            placeholder={
                                latitude
                                    ? latitude
                                    : 'Latitude ainda não informada'
                            }
                            value={latitude}
                            type="tel"
                            onChange={(e) => setLatitude(e.target.value)}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {saving ? (
                            <ButtonLoading>Salvando...</ButtonLoading>
                        ) : (
                            <Button onClick={() => updateShop()}>Salvar</Button>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
