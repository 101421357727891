import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    table: {
        width: '100%',
        maxWidth: '100%',
    },
    tableRow: {
        padding: 2,
    },
    carImage: {
        borderRadius: 3,
        objectFit: 'cover'
    }
});