import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 90,
    },
    root: {
        maxWidth: '100%',
        marginTop: '5rem',
    },
    cardVehicle: {
        maxWidth: '100%',
        marginTop: 10,
        marginBottom: 20,
    },
    deleteIcon: { marginRight: '10px', color: '#D84141' },
    carImage: { borderRadius: 5, marginRight: 10 },
    table: {
        width: '100%',
        maxWidth: '100%',
    },
    welcomeMessageContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 20,
        paddingRight: 20,
        background: '#fff',
        width: '100%',
        borderRadius: 3,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow:
            'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
    },
    welcomeTitle: {
        marginTop: 20,
        fontSize: 20,
        color: '#dc2626',
    },
    welcomeSubtitle: {
        marginTop: 20,
        fontSize: 16,
        color: '#929292',
    },
})
