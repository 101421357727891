import React, { useState, useEffect } from 'react'

import api from '../../../services/api'

import * as FileService from '../../../services/file'

import { toast } from 'react-toastify'

import {
    Button,
    TextField,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@material-ui/core'

import Skeleton from '@material-ui/lab/Skeleton'

import InputMask from 'react-input-mask'

import { AiOutlineDelete } from 'react-icons/ai'
import { FiSave } from 'react-icons/fi'

import { colors } from '../../../datas/colors'

export default function ModalEditCar({ isOpen, toggleModal, vehicleData }) {
    const { brand, model, id: vehicleId } = vehicleData

    const [accessories, setAccessories] = useState(vehicleData.accessories)
    const [year, setYear] = useState(vehicleData.year)
    const [price, setPrice] = useState(vehicleData.price)
    const [investment, setInvestment] = useState(vehicleData.investment)
    const [doors, setDoors] = useState(vehicleData.doors)
    const [color, setColor] = useState(vehicleData.color)
    const [licensePlate, setLicensePlate] = useState(vehicleData.license_plate)
    const [milage, setMilage] = useState(vehicleData.milage)
    const [description, setDescription] = useState(vehicleData.description)
    const [images, setImages] = useState([])
    const [loadingImages, setLoadingImages] = useState(true)

    const imageURL = vehicleData.image_url

    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        loadVehicleImages()
    }, [])

    async function saveVehicle() {
        setSaving(true)

        const updatedVehicle = {
            brand,
            model,
            category: 'carros',
            year,
            price,
            investment,
            doors,
            color,
            fuel_type: 'Gasolina',
            license_plate: licensePlate,
            milage,
            accessories: accessories,
            description,
            image_url: imageURL,
        }

        api.put(`/vehicles/${vehicleId}`, updatedVehicle).then(() => {
            toast.success('Alterações salvas com sucesso!')
            window.location.reload()
        })
    }

    async function deleteVehicle() {
        setDeleting(true)

        await api.delete(`/vehicles/${vehicleId}`)

        toast.success('Veículo deletado com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 800)
    }

    async function updateVehicleThumb(url) {
        api.put(`/vehicles/${vehicleId}`, { image_url: url }).then(() => {
            toast.success('😄 Imagem de capa do anúncio alterada!')
        })
    }

    function loadVehicleImages() {
        FileService.getVehicleImages(vehicleId).then((response) => {
            const images = response.data
            setImages(images)
            setLoadingImages(false)
        })
    }

    return (
        <Dialog
            disableEscapeKeyDown
            open={isOpen}
            onClose={toggleModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>{model}</DialogTitle>

            <DialogContent>
                <div
                    style={{
                        width: '100%',
                        overflowX: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 20,
                    }}
                >
                    <p style={{ marginBottom: 10, color: '#5F5F5F' }}>
                        Altere a <b>capa</b> do anúncio selecionando a imagem desejada.
                    </p>

                    <div
                        style={{
                            width: '100%',
                            overflowX: 'auto',
                            display: 'flex',
                        }}
                    >
                        {loadingImages ? (
                            <>
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={135}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 10,
                                    }}
                                />
                                <Skeleton
                                    variant="rect"
                                    width={130}
                                    height={135}
                                    style={{
                                        borderRadius: 5,
                                        marginLeft: 10,
                                        marginTop: 10,
                                    }}
                                />
                            </>
                        ) : (
                            images.map((image) => (
                                <img
                                    src={image.url}
                                    width="250"
                                    height="180"
                                    style={{
                                        borderRadius: 6,
                                        objectFit: 'cover',
                                        marginRight: '10px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => updateVehicleThumb(image.url)}
                                />
                            ))
                        )}
                    </div>
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Marca"
                        value={brand}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                        disabled
                    >
                        <MenuItem key={brand} value={brand}>
                            {brand}
                        </MenuItem>
                    </TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Modelo"
                        value={model}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                        disabled
                    >
                        <MenuItem key={model} value={model}>
                            {model}
                        </MenuItem>
                    </TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="9999"
                        value={year ? year : ''}
                        onChange={(event) => setYear(event.target.value)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="Ano"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Preço"
                        type="number"
                        value={price}
                        onChange={(event) =>
                            setPrice(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Investimento"
                        type="number"
                        value={investment}
                        onChange={(event) =>
                            setInvestment(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Cor"
                        value={color}
                        onChange={(event) => setColor(event.target.value)}
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {colors.map((color) => (
                            <MenuItem key={color} value={color}>
                                {color}
                            </MenuItem>
                        ))}
                    </TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Placa"
                        value={licensePlate}
                        onChange={(event) =>
                            setLicensePlate(event.target.value)
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Portas"
                        type="number"
                        value={doors ? doors : ''}
                        onChange={(event) =>
                            setDoors(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Km"
                        type="number"
                        value={milage}
                        onChange={(event) =>
                            setMilage(Number(event.target.value))
                        }
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginTop: 10,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Descrição"
                        rowsMax={5}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        variant="outlined"
                        style={{
                            marginRight: '10px',
                            marginBottom: '20px',
                            width: '100%',
                        }}
                        multiline
                    ></TextField>
                </form>
            </DialogContent>

            <DialogActions style={{ padding: 10 }}>
                <Button
                    onClick={toggleModal}
                    color="primary"
                    style={{ color: '#969696' }}
                >
                    Cancelar
                </Button>

                {deleting ? (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#E3E3E3' }}
                        disableElevation
                        disabled
                    >
                        Excluindo...
                    </Button>
                ) : (
                    <Tooltip title="Excluir" placement="top">
                        <Button
                            variant="contained"
                            onClick={() => deleteVehicle()}
                            color="primary"
                            style={{ backgroundColor: '#dc2626' }}
                            disableElevation
                        >
                            <AiOutlineDelete color="#fff" size={25} />
                        </Button>
                    </Tooltip>
                )}

                {saving ? (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#E3E3E3' }}
                        disableElevation
                        disabled
                    >
                        Salvando...
                    </Button>
                ) : (
                    <Tooltip title="Salvar" placement="top">
                        <Button
                            variant="contained"
                            onClick={() => saveVehicle()}
                            color="primary"
                            style={{ backgroundColor: '#55E342' }}
                            disableElevation
                        >
                            <FiSave color="#fff" size={25} />
                        </Button>
                    </Tooltip>
                )}
            </DialogActions>
        </Dialog>
    )
}
