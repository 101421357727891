import React, { useState, useEffect } from 'react'

import api from '../../../services/api'

import { toast } from 'react-toastify'

import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@material-ui/core'

import InputMask from 'react-input-mask'

import { useStyles } from './styles'

export default function ModalEditShop({ isOpen, toggleModal, shopData }) {
    const { id: shopId, username, email, is_admin, state, city, cpf } = shopData

    const [buttonAddIsLoading, setButtonAddIsLoading] = useState(false)
    const [buttonDeleteIsLoading, setButtonDeleteIsLoading] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState(shopData.phone_number)
    const [shopName, setShopName] = useState(shopData.shop_name)
    const [zipcode, setZipcode] = useState(shopData.zipcode)

    const updateShop = async () => {
        setButtonAddIsLoading(true)

        const shopData = {
            username,
            email,
            shop_name: shopName,
            phone_number: phoneNumber,
            zipcode,
            state,
            city,
            owner_cpf: cpf,
            active_account: true,
            is_admin,
        }

        await api.put(`/shop/${shopId}`, shopData)

        toast.success('Dados atualizados com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    const deleteShop = async () => {
        setButtonDeleteIsLoading(true)

        await api.delete(`/users/${shopId}`)

        toast.success('Loja deletada com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    return (
        <Dialog
            disableEscapeKeyDown
            open={isOpen}
            onClose={toggleModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>Informações da loja</DialogTitle>
            <DialogContent>
                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Nome da loja"
                        type="text"
                        value={shopName}
                        onChange={(event) => setShopName(event.target.value)}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Nome de usuário"
                        type="text"
                        value={username}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="E-mail"
                        type="text"
                        value={email}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="(48) 9-9999-9999"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="Telefone"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <InputMask
                        mask="99.999-999"
                        value={zipcode}
                        onChange={(event) => setZipcode(event.target.value)}
                        onKeyPress={(e) =>
                            e.key == 'Enter' ? e.preventDefault() : null
                        }
                        style={{ marginRight: '10px', width: '100%' }}
                    >
                        {() => (
                            <TextField
                                id="outlined-select-currency"
                                label="CEP"
                                type="text"
                                variant="outlined"
                                style={{ marginRight: '10px', width: '100%' }}
                            />
                        )}
                    </InputMask>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Estado"
                        type="text"
                        value={state}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                        disabled
                    ></TextField>
                </form>

                <form
                    noValidate
                    autoComplete="off"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 14,
                    }}
                >
                    <TextField
                        id="outlined-select-currency"
                        label="Estado"
                        type="text"
                        value={city}
                        variant="outlined"
                        style={{ marginRight: '10px', width: '100%' }}
                        disabled
                    ></TextField>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={toggleModal}
                    color="primary"
                    style={{ color: '#969696' }}
                >
                    Cancelar
                </Button>

                {buttonDeleteIsLoading ? (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#E3E3E3' }}
                        disableElevation
                        disabled
                    >
                        Excluindo...
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => deleteShop()}
                        color="primary"
                        style={{ backgroundColor: '#dc2626' }}
                        disableElevation
                    >
                        Excluir
                    </Button>
                )}

                {buttonAddIsLoading ? (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#E3E3E3' }}
                        disableElevation
                        disabled
                    >
                        Salvando...
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() => updateShop()}
                        color="primary"
                        style={{ backgroundColor: '#55E342' }}
                        disableElevation
                    >
                        Salvar
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
