import React, { PureComponent } from 'react'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'

export default class AnalyticsLineChart extends PureComponent {
    render() {
        return (
            <div style={{ width: '100%', height: 300, padding: 15 }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={this.props.data}
                        margin={{
                            top: 25,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="price" />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="price"
                            stroke="#FF5A5F"
                            fill="#FF5A5F"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
}
