import styled from 'styled-components'

export const NavbarContainer = styled.div`
    width: 100%;
    background-color: #fff;
`

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    color: #878787;

    @media (max-width: 1920px) {
        width: 60%;
    }

    @media (max-width: 1440px) {
        width: 70%;
    }


    @media (max-width: 1024px) {
        width: 90%;
    }

    ul {
        list-style-type: none;

        li {
            float: left;
            text-decoration: none;
            padding: 13px;
            font-size: 13pt;
            color: #878787;

            &:hover {
                background-color: #F8F8F8;
                border-radius: 15px;
                cursor: pointer;
            }
        }

        button {
            background-color: white;
            border: none;
            border-radius: 15px;
            margin-left: 10px;
            font-size: 11pt;
            padding: 15px;
            padding-right: 25px;
            padding-left: 25px;
            box-shadow: 0px 0px 5px #ccc;

            &:hover {
                transition: 0.2s box-shadow;
                box-shadow: 0px 0px 10px #ccc;
                cursor: pointer;
            }
        }
    }
`

export const Logo = styled.img`
     width: 120px;
     margin-top: 10px;
`