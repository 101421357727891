import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import { Helmet } from 'react-helmet'
import { Adsense } from '@ctrl/react-adsense'

import { BsSearch } from 'react-icons/bs'
import { SearchBar, Input, Button } from './styles'

import ReactGA from 'react-ga'

import CircularProgress from '@material-ui/core/CircularProgress'

import Navbar from '../../components/Navbars/Navbar'
import ListGarages from '../../components/ListGarages'

export default function Garages() {
    const [shops, setShops] = useState(null)
    const [searchParams, setSearchParams] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        loadShops()
    }, [])

    async function loadShops() {
        const response = await api.get('shops')

        setShops(response.data)
        setLoading(false)
    }

    async function loadSearchedShop() {
        setLoading(true)

        if (!searchParams) {
            setLoading(false)
            return false
        }

        const response = await api.post('search/shops', { searchParams })

        setShops(response.data)
        setLoading(false)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Lojas | meunovousado.com.br</title>
                <meta
                    name="description"
                    content="Encontre lojas em Criciúma
                     e região"
                ></meta>
                <meta
                    name="keywords"
                    content="carros usados, carros em criciúma e região, carros usados a venda, carros a venda"
                ></meta>
            </Helmet>

            <Navbar />

            <SearchBar>
                <p style={{ color: '#797979', fontSize: 20 }}>
                    Lojas encontradas <br />
                    <small>
                        {loading ? (
                            <CircularProgress
                                size={15}
                                color="#949494"
                                value={10}
                            />
                        ) : (
                            `${
                                shops.length > 0
                                    ? `${shops.length} resultados`
                                    : 'Nenhum resultado encontrado'
                            }`
                        )}
                    </small>
                </p>

                <Input
                    value={searchParams}
                    placeholder="Procurar por uma garagem específica..."
                    onChange={(e) => setSearchParams(e.target.value)}
                    onKeyPress={(e) =>
                        e.key == 'Enter' ? loadSearchedShop() : null
                    }
                />

                <Button onClick={() => loadSearchedShop()}>
                    <BsSearch fill="white" style={{ marginRight: '10px' }} />
                    Buscar
                </Button>
            </SearchBar>

            <ListGarages shops={shops} loading={loading} showTitle={false} />

            <Adsense
                client="ca-pub-2071790119973662"
                slot="5524198658"
                style={{ display: 'block' }}
                layout="in-article"
                format="auto"
            />
        </>
    )
}
