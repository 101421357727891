import React from 'react'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import { useStyles } from './styles'

import carGrey from '../../assets/images/carGrey.png'

function Footer() {
    const classes = useStyles()

    return (
        <Grid container className={classes.footerContainer}>
            <Grid item xs={12} sm={7} className={classes.footerHeaderLogo}>
                <p className={classes.footerLogoTitle}>
                    <img
                        src={carGrey}
                        width="50"
                        height="50"
                        alt="Logo"
                        style={{ marginRight: '6px' }}
                    />{' '}
                    meunovousado
                </p>
            </Grid>

            <Grid
                item
                xs={6}
                sm={2}
                className={classes.footerCategoryContainer}
            >
                <ul>
                    <p className={classes.footerLinkTitle}>Explore</p>

                    <Link to="/lojas" className={classes.footerLink}>
                        <li>Lojas</li>
                    </Link>
                    <Link to="/veiculos" className={classes.footerLink}>
                        <li>Veículos</li>
                    </Link>
                    <Link to="/administrativo" className={classes.footerLink}>
                        <li>Administrativo</li>
                    </Link>
                </ul>
            </Grid>

            <Grid
                item
                xs={6}
                sm={2}
                className={classes.footerCategoryContainer}
            >
                <ul>
                    <p className={classes.footerLinkTitle}>Siga-nos</p>

                    <a href="#" className={classes.footerLink}>
                        <li className={classes.footerLink}>Blog</li>
                    </a>
                    <a
                        href="https://www.instagram.com/meunovousado.com.br/"
                        target="_blank"
                        className={classes.footerLink}
                    >
                        <li className={classes.footerLink}>Instagram</li>
                    </a>
                    <a
                        href="https://www.facebook.com/meunovousado.com.br/"
                        target="_blank"
                        className={classes.footerLink}
                    >
                        <li className={classes.footerLink}>Facebook</li>
                    </a>
                </ul>
            </Grid>

            <Grid item sm={12} className={classes.footerCopyRight}>
                meunovousado.com.br
            </Grid>
        </Grid>
    )
}

export default Footer
