import { makeStyles } from '@material-ui/core'

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
    // root: {
    //     display: 'flex',
    // },
    // drawer: {
    //     [theme.breakpoints.up('sm')]: {
    //         width: drawerWidth,
    //         flexShrink: 0,
    //     },
    // },
    // appBar: {
    //     zIndex: theme.zIndex.drawer + 1,
    //     background: '#fff',
    //     boxShadow: '0 1px 1px 1px rgba(200, 200, 200, .1)',
    // },
    // menuButton: {
    //     color: '#000',
    //     marginRight: theme.spacing(3),
    //     [theme.breakpoints.up('sm')]: {
    //         display: 'none',
    //     },
    // },
    // toolbar: theme.mixins.toolbar,
    // drawerPaper: {
    //     width: drawerWidth
    // },
    // content: {
    //     flexGrow: 1,
    //     padding: theme.spacing(3),
    // },
    // closeMenuButton: {
    //     marginRight: 'auto',
    //     marginLeft: 0,
    // },
      root: {
    display: 'flex',
  },
  appBar: {
    background: '#fff',
    boxShadow: '0px 0px 2px #CDCDCD',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0, // hide sidebar icons on dashboard navbar
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
    homeIcon: { marginRight: '10px', color: '#2B2B2B'  },
    categoryIcon: { marginRight: '10px', color: '#2B2B2B'  },
    bookIcon: { marginRight: '10px', color: '#2B2B2B' },
    link: { textDecoration: 'none', color: '#dc2626', display: 'flex', alignItems: 'center' },
    linkText: { color: '#000' }
}));