import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import { Helmet } from 'react-helmet'

import ReactGA from 'react-ga'

import Grid from '@material-ui/core/Grid'

import { BsSearch } from 'react-icons/bs'

import {
    HeaderContainer,
    SearchButton,
    SearchBarContainer,
    SearchInput,
} from './styles'

import Navbar from '../../components/Navbars/Navbar'
import Footer from '../../components/Footer'
import ListGarages from '../../components/ListGarages'
import ListVehicles from '../../components/ListVehicles'
import CookieConset from '../../components/CookieConsent'

export default function Home(props) {
    const [searchParams, setSearchParams] = useState(null)
    const [vehicles, setVehicles] = useState([])
    const [shops, setShops] = useState([])
    const [loadingVehicles, setLoadingVehicles] = useState(true)
    const [loadingShops, setLoadingShops] = useState(true)

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)

        loadVehicles()
        loadShops()
    }, [])

    async function loadVehicles() {
        const response = await api.get('vehicles')

        setVehicles(response.data)
        setLoadingVehicles(false)
    }

    async function loadShops() {
        const response = await api.get('shops')

        setShops(response.data)
        setLoadingShops(false)
    }

    function redirectPage (route) {
        props.history.push(route)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Meu Novo Usado | Veja como é simples encontrar seu próximo
                    veículo
                </title>
                <meta
                    name="description"
                    content="As melhores ofertas de carros usados em Criciúma e Região você só encontra aqui!"
                ></meta>
                <meta
                    name="keywords"
                    content="carros usados, carros em criciúma e região, carros usados a venda, carros a venda"
                ></meta>
            </Helmet>

            {!loadingShops && !loadingVehicles && <CookieConset />}

            <HeaderContainer>
                <Navbar />

                <Grid
                    container
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        margin: '0 auto',
                        paddingTop: 30,
                        width: '85vw',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        xl={4}
                        sm={6}
                        style={{ marginBottom: 15 }}
                    >
                        <p style={{ fontSize: 23, color: '#797979' }}>
                            As melhores ofertas
                        </p>
                        <p style={{ fontSize: 16, color: '#949494' }}>
                            de carros usados da sua região
                        </p>

                        <SearchBarContainer>
                            <SearchInput
                                type="text"
                                placeholder="Busque por modelo. Ex: Sandero"
                                onChange={(e) =>
                                    setSearchParams(e.target.value)
                                }
                                onKeyPress={(e) =>
                                    e.key == 'Enter'
                                        ? props.history.push(
                                              `/veiculos/${
                                                  searchParams
                                                      ? searchParams
                                                      : ''
                                              }`
                                          )
                                        : null
                                }
                            />

                            <SearchButton
                                onClick={() =>
                                    props.history.push(
                                        `/veiculos/${
                                            searchParams ? searchParams : ''
                                        }`
                                    )
                                }
                            >
                                <BsSearch
                                    fill="white"
                                    style={{ marginRight: '10px' }}
                                />
                                Buscar
                            </SearchButton>
                        </SearchBarContainer>
                    </Grid>
                </Grid>
            </HeaderContainer>

            <ListVehicles
                vehicles={vehicles}
                loading={loadingVehicles}
                showTitle
                withContainer
                withMoreOffersButton
                redirectPage={redirectPage}
                title="Ofertas recentes"
                limit={8}
            />

            <ListGarages
                shops={shops}
                loading={loadingShops}
                showTitle
                title="Encontre lojas"
                limit={6}
            />

            <Footer />
        </>
    )
}
