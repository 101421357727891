import React, { useState, useEffect } from 'react'

import api from '../../../services/api'
import jwt from 'jsonwebtoken'
import { Helmet } from 'react-helmet'

import { ToastContainer, toast } from 'react-toastify'

import {
    Container,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Divider,
} from '@material-ui/core/'

import NavbarDashboard from '../../../components/Navbars/NavbarDashboard'
import ModalAddPartner from '../../../components/Modals/ModalAddPartner'

import partnersLogo from '../../../assets/images/partners.svg'

import { AiOutlineDelete, AiOutlineUserAdd } from 'react-icons/ai'

import { useStyles } from './styles'

export default function DashboardProfile() {
    const classes = useStyles()

    const { REACT_APP_JWT_SECRET_KEY } = process.env

    const jwtToken = localStorage.getItem('token')
    const jwtPayload = jwt.decode(jwtToken, REACT_APP_JWT_SECRET_KEY)

    const { id: shopId } = jwtPayload

    const [partners, setPartners] = useState(true)
    const [loading, setLoading] = useState(true)

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    }

    useEffect(() => {
        async function loadParters() {
            const response = await api.get(`shop/${shopId}/partners`)

            setPartners(response.data)
            setLoading(false)
        }
        
        if (shopId) {
            loadParters()
        }
    }, [shopId])

    async function deletePartner(id) {
        await api.delete(`partners/${id}`)

        toast.success('Sócio retirado com sucesso!')

        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Configurações | meunovousado.com.br</title>
            </Helmet>

            <NavbarDashboard />

            <ToastContainer autoClose={3000} />

            <Container maxWidth="md" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#737373',
                                fontSize: 24,
                                textAlign: 'left',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 0,
                            }}
                        >
                            Configurações
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#AEAEAE',
                                fontSize: 15,
                                margin: 0,
                                marginBottom: 20,
                            }}
                        >
                            Ajuste a plataforma para melhor se adequar as
                            necessidades da sua loja.
                        </Typography>

                        <Divider style={{ marginTop: 10, width: '50%' }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#737373',
                                fontSize: 22,
                                textAlign: 'left',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 0,
                            }}
                        >
                            Sócios
                            <IconButton
                                aria-label="edit"
                                onClick={() => toggleModal()}
                            >
                                <AiOutlineUserAdd size={18} />
                            </IconButton>
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="p"
                            component="p"
                            style={{
                                color: '#AEAEAE',
                                fontSize: 15,
                                margin: 0,
                                marginBottom: 20,
                            }}
                        >
                            Adicione sócios a sua loja para ter mais de um meio
                            de contato.
                        </Typography>

                        {partners.length <= 0 ? (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: 40,
                                }}
                            >
                                <img src={partnersLogo} width={200} />
                                <Typography
                                    gutterBottom
                                    variant="p"
                                    component="p"
                                    style={{
                                        color: '#AEAEAE',
                                        fontSize: 15,
                                        margin: 0,
                                        marginBottom: 20,
                                        marginTop: 15,
                                    }}
                                >
                                    Ainda não há sócios adicionados
                                </Typography>
                            </div>
                        ) : (
                            <TableContainer
                                component={Paper}
                                style={{
                                    borderBottom: 'none',
                                    marginBottom: 50,
                                }}
                                variant="outlined"
                            >
                                <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">
                                                Nome
                                            </TableCell>
                                            <TableCell align="right">
                                                E-mail
                                            </TableCell>
                                            <TableCell align="right">
                                                Número
                                            </TableCell>
                                            <TableCell align="right">
                                                Opções
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!loading &&
                                            partners.map((partner) => (
                                                <>
                                                    <TableRow key={partner.id}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            #{partner.id}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {String(
                                                                partner.name
                                                            ).toUpperCase()}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {partner.email}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {
                                                                partner.phone_number
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip
                                                                title="Excluir"
                                                                placement="top"
                                                            >
                                                                <IconButton
                                                                    aria-label="edit"
                                                                    onClick={() =>
                                                                        window.confirm(
                                                                            'Tem certeza que deseja deletar um registro? Essa ação não poderá ser revertida.'
                                                                        )
                                                                            ? deletePartner(
                                                                                  partner.id
                                                                              )
                                                                            : null
                                                                    }
                                                                >
                                                                    <AiOutlineDelete />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Grid>

                <ModalAddPartner
                    isOpen={modalIsOpen}
                    toggleModal={toggleModal}
                />
            </Container>
        </>
    )
}
